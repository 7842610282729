.toast {
    position: fixed;
    bottom: 1em;
    right: 1em;
    min-width: 22.5vw;
    box-sizing: border-box;
    padding: 1em;
}

.toast-mobile {
    position: absolute;
    top: 2rem;
    left: 2rem;
    width: calc(100vw - 4rem);
    box-sizing: border-box;
    padding: 2rem;
    z-index: 1000000;
}

input[type='checkbox'].toggle {
    opacity: 0;
    position: absolute;
    left: -9000px;
    top: -9000px;
}

input[type='checkbox'].toggle + .blabel {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--corporate-color-1);
}

input[type='checkbox'].toggle + .blabel::before {
    content: '';
    width: 2.5em;
    height: 1.25em;
    background-color: white;
    border-radius: 1em;
    transition: background-color 200ms ease-in-out;
    border: 1px solid var(--corporate-color-1-light);
    box-sizing: border-box;
}

input[type='checkbox'].toggle + .blabel::after {
    position: absolute;
    content: ' ';
    width: 1.5em;
    height: 1.5em;
    background-color: #66859b;
    color: white;
    border-radius: 1em;
    transition: background-color 200ms ease-in-out, transform 200ms ease-in-out;
}

input[type='checkbox'].toggle:focus + .blabel::before {
    outline: 1px solid black;
    border: 1px solid var(--corporate-color-1-light);
    box-sizing: border-box;
}

input[type='checkbox'].toggle:checked + .blabel::before {
    background-color: hsl(100, 70%, 90%);
    border: 1px solid var(--corporate-color-13);
    box-sizing: border-box;
}

input[type='checkbox'].toggle:checked + .blabel::after {
    content: ' ';
    transform: translateX(70%);
    background-color: var(--corporate-color-13);
}

input[type='checkbox'].toggle:disabled + .blabel {
    color: #777;
}

input[type='checkbox'].toggle:disabled + .blabel::before {
    background-color: #ccc;
}

input[type='checkbox'].toggle:disabled + .blabel::after {
    background-color: #777;
}

/* Mobile */

input[type='checkbox'].toggle-mobile {
    opacity: 0;
    position: absolute;
    left: -9000px;
    top: -9000px;
}

input[type='checkbox'].toggle-mobile + .blabel {
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: var(--corporate-color-1);
}

input[type='checkbox'].toggle-mobile + .blabel::before {
    content: '';
    width: 9.5rem;
    height: 5rem;
    background-color: white;
    border-radius: 3rem;
    transition: background-color 200ms ease-in-out;
    border: 1px solid var(--corporate-color-1-light);
}

input[type='checkbox'].toggle-mobile + .blabel::after {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    content: ' ';
    left: 0.2em;
    width: 4.5rem;
    height: 4.5rem;
    background-color: #66859b;
    color: white;
    border-radius: 3rem;
    transition: background-color 200ms ease-in-out, transform 200ms ease-in-out;
}

input[type='checkbox'].toggle-mobile:focus + .blabel::before {
    outline: 1px solid black;
    border: 1px solid var(--corporate-color-1-light);
}

input[type='checkbox'].toggle-mobile:checked + .blabel::before {
    background-color: hsl(100, 70%, 90%);
    border: 1px solid var(--corporate-color-13);
}

input[type='checkbox'].toggle-mobile:checked + .blabel::after {
    content: ' ';
    transform: translateX(100%);
    background-color: var(--corporate-color-13);
}

input[type='checkbox'].toggle-mobile:disabled + .blabel {
    color: #777;
}

input[type='checkbox'].toggle-mobile:disabled + .blabel::before {
    background-color: #ccc;
}

input[type='checkbox'].toggle-mobile:disabled + .blabel::after {
    background-color: #777;
}

.fancy-checkbox-container {
    margin-left: 0.25rem;
    width: 2.2em;
    height: 1.1em;
    border-radius: 1em;
    border: 1px solid var(--corporate-color-1-light);
    position: relative;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.box-bobble {
    height: 1.5em;
    width: 1.5em;
    border-radius: 1em;
    position: absolute;
}
