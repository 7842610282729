/* VARS */
:root {
    --font-family: 'Helvetica', sans-serif;
    --font-size-small: 0.7em;
    --font-size-normal: 1em;
    --font-size-large: 1.3em;
    --font-size-big: 2em;
    --font-size-extra-big: 3em;
    --font-size-mobile-small: 1.5em;
    --font-size-mobile-normal: 3em;
    --font-size-mobile-large: 4em;
    --font-size-mobile-big: 5em;
    --space-small: 0.5em;
    --space-norsmall: 0.75em;
    --space-normal: 1em;
    --space-normbig: 1.25em;
    --space-normal-row: 1.5em;
    --space-large-row: 1.75em;
    --space-big: 2em;
    --control-height: 2.3em;
    --control-label-pos: 0.2em;
    --color-1: rgb(70, 70, 70);
    --color-2: darkgray;
    --color-3: #e0e0e0;
    --color-3-light: #e0e0e075;
    --color-4: #fff;
    --color-success: green;
    --color-error: #ff0000;
    --color-error-light: #ff000073;
    --color-info: blue;
    --white-opac: rgba(256, 256, 256, 0.75);
    --white-opac2: rgba(256, 256, 256, 0.95);
    --shadow-color-dark: rgba(0, 0, 0, 0.75);
    --shadow-color-normal: rgba(0, 0, 0, 0.5);
    --shadow-color-light: rgba(0, 0, 0, 0.3);
    --shadow-color-ultralight: rgba(0, 0, 0, 0.17);
    --corporate-color-1: #003459;
    --corporate-color-1-light: #00345966;
    --corporate-color-1-light2: #00345980;
    --corporate-color-1-light3: #003459e6;
    --corporate-color-1-light4: #00345933;
    --corporate-color-1-light5: #0034594d;
    --corporate-color-2: #005980;
    --corporate-color-2-light: #0059808c;
    --corporate-color-2-light2: #005980bf;
    --corporate-color-3: #007ea7;
    --corporate-color-3-light: #007ea726;
    --corporate-color-4: #64a6c1;
    --corporate-color-5: #96bace;
    --corporate-color-5l: #96bace5e;
    --corporate-color-5-light2: #96bacebf;
    --corporate-color-6: #c7cedb;
    --corporate-color-6-light: #c7cedbbf;
    --corporate-color-6-light2: #c7cedbf7;
    --corporate-color-6-ultralight: #c7cedb40;
    --corporate-color-6-modal: #e3e7ed;
    --corporate-color-7: #ffd166;
    --corporate-color-7-light: #ffd16626;
    --corporate-color-7-light2: #ffd1668c;
    --corporate-color-7-light3: #ffd166bf;
    --corporate-color-7-light4: #ffd1669e;
    --corporate-color-8: #e3d0a1;
    --corporate-color-9: #a5668b;
    --corporate-color-10: #70163c;
    --corporate-color-10-light: #70163c80;
    --corporate-color-10-light2: #70163cbf;
    --corporate-color-11: #0ea700e1;
    /* Top badge colors a, s, d */
    --corporate-color-12: #ff3a20;
    --corporate-color-13: #16db65;
    --corporate-color-13-light: #16db6580;
    --corporate-color-13-light2: #16db65bf;
    --corporate-color-13-light3: #16db65e6;
    --corporate-color-13-light4: #16db6540;
    --corporate-color-13-light5: #16db651a;
    --corporate-color-14: #0caadc;
    --corporate-color-14-light: #0caadc80;
    --corporate-color-14-light2: #0caadc40;
    --corporate-color-14-light3: #0caadc1a;
}

/* COMMON */

body,
body * {
    font-family: var(--font-family);
    font-size: 14px;
    margin: 0;
}

.font-weight-100 {
    font-weight: 100;
}

.font-weight-bold {
    font-weight: 100;
}

p {
    cursor: default;
}

button p {
    cursor: pointer;
    margin-left: 6px;
}

button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: none;
    border-radius: 20px;
    background-color: transparent;
    cursor: pointer;
    color: var(--corporate-color-1);
    height: var(--control-height);
    padding: var(--space-norsmall);
    transition: 0.2s ease;
}

button:hover {
    border: none;
    background-color: var(--shadow-color-ultralight);
    cursor: pointer;
}

input,
textarea {
    border: none;
    border-radius: var(--space-small);
    padding-left: var(--space-small);
}

textarea {
    padding-top: var(--space-small);
}

:focus,
:focus-visible,
:active {
    outline: none;
    background-color: transparent;
}

.pdf-viewer {
    border-radius: var(--space-small);
    overflow: hidden;
    flex: 1;
}

.offline-syncer {
    background-color: var(--color-3);
    border: 2px solid var(--color-error);
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px var(--shadow-color-light);
}

/* TOASTS */

.toast.success {
    background-color: white;
    border: 2px solid var(--corporate-color-13);
    border-radius: 35px;
    box-shadow: 0 10px 20px -2px var(--corporate-color-13);
    color: var(--corporate-color-13);
    animation: toast-animation 0.6s ease;
}

.toast.spinner {
    background-color: white;
    border: 2px solid var(--corporate-color-3);
    border-radius: 35px;
    box-shadow: 0 10px 20px -2px var(--corporate-color-3);
    color: var(--corporate-color-3);
    animation: toast-animation 0.6s ease;
}

.toast.error {
    background-color: white;
    border: 2px solid var(--corporate-color-10);
    border-radius: 35px;
    box-shadow: 0 10px 20px -2px var(--corporate-color-10);
    color: var(--corporate-color-10);
    animation: toast-animation 0.6s ease;
}

.toast.hint {
    background-color: white;
    border: 2px solid var(--corporate-color-14);
    border-radius: 35px;
    box-shadow: 0 10px 20px -2px var(--corporate-color-14);
    color: var(--corporate-color-14);
    animation: toast-animation 0.6s ease;
}

/* MODALS */

.modal-container {
    border-radius: var(--space-normbig);
    overflow: hidden;
    box-shadow: 0px 0px 15px 3px var(--shadow-color-normal);
    z-index: 3;
    animation: modal-scaler 0.4s ease;
}

.modal-backdrop.open {
    z-index: 3;
    background-color: rgba(0, 0, 0, 0.5);
    animation: background-opacer 0.4s ease;
}

.modal-container .content-container {
    background-color: rgba(248, 247, 248);
    border-style: none;
    padding: var(--space-normal);
    overflow-y: scroll;
}

.modal-container .content-container .columns {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    flex-wrap: wrap;
    gap: var(--space-normal);
    flex: 1;
}

.modal-container .content-container .columns > * {
    display: flex;
    flex-direction: column;
    flex: 1;
    row-gap: var(--space-small);
}

.modal-container .content-container .opp-headline {
    display: flex;
    align-items: center;
    color: var(--corporate-color-2);
    font-size: 26px;
    font-weight: 100;
    border-bottom-style: solid;
    border-bottom-color: var(--corporate-color-1-light);
    border-bottom-width: 1px;
    cursor: default;
}

.modal-container .content-container .modal-headline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--corporate-color-2);
    cursor: default;
    margin-bottom: var(--space-normal);
    margin-top: var(--space-small);
}

.modal-container .content-container .modal-headline div {
    display: flex;
    align-items: center;
    flex: 1;
    font-size: 28px;
    font-weight: 100;
}

.modal-container .content-container .modal-subheadline {
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: var(--corporate-color-2);
    cursor: default;
    margin-bottom: var(--space-small);
    margin-top: var(--space-normal);
    flex: 1;
    font-size: 22px;
    font-weight: 100;
}

.modal-container .content-container .actions-headline {
    color: var(--corporate-color-2);
    font-size: 24px;
    font-weight: 100;
    border-bottom-style: solid;
    border-bottom-color: var(--corporate-color-1-light);
    border-bottom-width: 1px;
    margin-top: 18;
    cursor: default;
}

.modal-container .content-container .opp-timeline {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 6px;
    margin-right: 6px;
    margin-top: var(--space-normal-row);
}

.modal-container .topbar-container button {
    border-radius: 0;
}

.modal-container .topbar-container {
    display: flex;
    align-items: center;
    height: 30px;
    padding: var(--space-small);
    padding-left: var(--space-normal);
    background-color: var(--corporate-color-2);
    color: var(--color-1);
    border-style: none;
}

.modal-container .topbar-container .topbar-icons {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: var(--space-big);
}
.modal-container .topbar-container > * {
    height: 35px;
}

.screen-container .modal-container-compensation .topbar-container > * {
    width: fit-content;
    height: 35px;
}

.modal-container .types-container {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

/* SCREENS */

.screen-container.topbar-container {
    width: 100%;
    height: 120px;
}

.screen-container .topbar-container > * {
    width: 100%;
    /* height: 120px; */
}

.screen-container .topbar-container > div > *:last-child {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    box-shadow: 0 5px 8px -2px var(--corporate-color-1-light4);
    transition: 0.7s ease;
}

/* LIST */

.list-row {
    background-color: white;
    margin-left: var(--space-large-row);
    margin-right: var(--space-large-row);
    margin-top: 2.25em;
    border-radius: var(--space-normbig);
    box-shadow: 0 5px 8px -2px var(--corporate-color-1-light4);
}

.list-row input {
    border: 1px solid var(--corporate-color-1-light5);
    transition: 0.2s ease;
}

.list-row input.datepicker {
    width: 150px;
}

.list-row textarea {
    border: 1px solid var(--corporate-color-1-light5);
    transition: 0.2s ease;
}

.list-row input:hover {
    border: 1px solid var(--corporate-color-2);
}

.list-row textarea:hover {
    border: 1px solid var(--corporate-color-2);
}

.list-row input:focus {
    border: 1px solid var(--corporate-color-1);
}

.list-row textarea:focus {
    border: 1px solid var(--corporate-color-1);
}

.attendee-row {
    display: flex;
    flex-direction: row;
    width: 70%;
    justify-content: space-around;
    align-items: center;
}

.attendee-row .field.text-field {
    width: 30%;
    margin-left: 24px;
}

.attendee-row .field.dropdown-field {
    width: 17.5%;
    margin-left: 24px;
}

.list-row-buttons {
    display: flex;
    justify-content: flex-end;
    position: relative;
    height: 0;
    border-radius: var(--space-normbig);
}

/* HEADER */

.content-header-container {
    background-color: var(--corporate-color-6);
    height: auto;
    padding-left: 26px;
    padding-right: 26px;
    padding-bottom: 12px;
    border-radius: 32px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: 12px;
}

.attendees-section {
    display: flex;
    flex-direction: column;
    padding-top: 6px;
}

/* FIELDS */
.field-column {
    display: flex;
    flex-direction: row;
    gap: var(--space-small);
}

.filter-row {
    display: flex;
    flex-direction: row;
    gap: var(--space-small);
    justify-content: center;
    align-items: flex-start;
}

.field-column button {
    flex: 1;
}
.field-column .field {
    flex: 3;
}

.field {
    display: flex;
    flex-direction: column;
    min-height: var(--control-height);
    position: relative;
}

.kanban-top .field {
    display: flex;
    flex-direction: column;
    min-height: var(--control-height);
    align-items: flex-start;
}

.field .input {
    background-color: var(--color-4);
    height: 100%;
    min-height: var(--control-height);
    padding: var(--space-small);
    border-radius: var(--space-small);
}

.field.image-field .input {
    height: 100%;
    min-height: 70px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.field.file-field .input {
    height: 60px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
}

.field textarea {
    resize: vertical;
}

.label {
    cursor: default;
}

.field .label {
    font-size: var(--font-size-small);
    display: flex;
    opacity: 0;
    position: relative;
    height: auto;
    margin-top: var(--control-label-pos);
    font-size: var(--font-size-small);
    transition: opacity 0.4s ease;
    color: var(--corporate-color-2);
}

.label-desktop {
    font-size: var(--font-size-small);
    display: flex;
    opacity: 1;
    position: relative;
    height: auto;
    font-size: var(--font-size-small);
    transition: opacity 0.4s ease;
    color: var(--corporate-color-2);
}

.field.selected .label {
    opacity: 1;
}

.field.list-field .input {
    padding: 0;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    border-radius: var(--space-normal);
}

.field.list-field .row {
    cursor: pointer;
    padding: var(--space-small);
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid var(--color-3);
}

.field.list-field .row .buttons {
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.field.list-field .row .buttons .label {
    font-size: var(--font-size-normal);
    margin-left: 6px;
    margin-right: 12px;
    margin-top: 0px;
}

.field.list-field .row .buttons .checkbox {
    display: flex;
    align-items: center;
}

.field.list-field .row:hover {
    background-color: var(--color-3-light);
}

.field.list-field .row.selected {
    background-color: var(--color-2);
    color: var(--color-4);
}

.list-row-entry {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: inherit;
    font-weight: 100;
}

.datepicker-calender {
    z-index: 10;
    position: relative;
}

.field.date-field.xtra-small {
    width: 10.5ch;
    z-index: 1000;
}
.field.date-field.xtra-small .datepicker {
    width: 100%;
    z-index: 1000;
}

.field.date-field.small {
    width: 12ch;
}
.field.date-field.small .datepicker {
    width: 100%;
}

.field.date-field.medium {
    width: 20ch;
}
.field.date-field.medium .datepicker {
    width: 100%;
}

.field.project-selcetor-dropdown-field {
    min-width: 25ch;
}

.field.multidropdown-field {
    min-width: 20ch;
}

.field.multidropdown-field .combo {
    height: var(--control-height);
    padding: 0 var(--space-small);
    background-color: var(--color-4);
    border-radius: var(--space-small);
}

.field.multidropdown-field .selected-option {
    background-color: var(--color-3);
    border-radius: var(--space-small);
}

.field.multidropdown-field .options {
    background-color: var(--color-3);
    z-index: 1000;
    max-height: 300px;
    overflow-y: scroll;
}

.dropdown-field {
    width: 149px;
}

.datepicker {
    height: var(--control-height);
}

.animated-searchbar {
    display: flex;
    align-items: center;
    animation: slider-search 0.7s ease;
    flex: 1;
}

.animated-searchbar input {
    box-sizing: border-box;
    height: 32px;
    border-radius: 17px;
    padding-left: 20px;
    flex: 1;
    border: 1px solid var(--corporate-color-1-light);
    transition: 0.2s ease;
}

.animated-searchbar input:hover {
    border: 1px solid var(--corporate-color-6-light);
    box-shadow: inset 0px 0px 3px 0px var(--shadow-color-normal);
}

.animated-searchbar input:focus {
    border: 1px solid var(--corporate-color-1-light2);
    box-shadow: inset 0px 0px 3px 0px var(--corporate-color-1-light);
}

.animated-searchbar-half {
    display: flex;
    align-items: center;
    animation: slider-search-half 0.7s ease;
    flex: 1;
}

.animated-searchbar-half input {
    box-sizing: border-box;
    height: 32px;
    border-radius: 17px;
    padding-left: 20px;
    flex: 1;
    border: 1px solid var(--corporate-color-1-light);
    transition: 0.2s ease;
}

.animated-searchbar-half input:hover {
    border: 1px solid var(--corporate-color-6-light);
    box-shadow: inset 0px 0px 3px 0px var(--shadow-color-normal);
}

.animated-searchbar-half input:focus {
    border: 1px solid var(--corporate-color-1-light2);
    box-shadow: inset 0px 0px 3px 0px var(--corporate-color-1-light);
}

.searchbar {
    box-sizing: border-box;
    height: 35px;
    border-radius: 17px;
    padding-left: 20px;
    width: 100%;
    margin: 12 0 12 0;
    border: 1px solid var(--corporate-color-1-light);
    transition: 0.2s ease;
}

.searchbar:hover {
    border: 1px solid var(--corporate-color-6-light);
    box-shadow: inset 0px 0px 3px 0px var(--shadow-color-normal);
}

.searchbar:focus {
    border: 1px solid var(--corporate-color-1-light2);
    box-shadow: inset 0px 0px 3px 0px var(--corporate-color-1-light);
}

.dashboard-searchbar {
    height: 35px;
    border-radius: 17px;
    padding-left: 20px;
    width: 100%;
    margin: 12px 0px 12px 0px;
    border: 1px solid var(--corporate-color-1-light);
    transition: 0.2s ease;
}

.dashboard-searchbar:hover {
    border: 1px solid var(--corporate-color-1-light);
    box-shadow: inset 0px 0px 4px 0px var(--corporate-color-1-light);
}

.dashboard-searchbar:focus {
    border: 1px solid var(--corporate-color-1-light2);
    box-shadow: inset 0px 0px 4px 0px var(--corporate-color-1-light);
}

/* BUTTONS */

.button-icon {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: var(--corporate-color-1);
}

.save-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--corporate-color-1);
    color: white;
    font-size: 18px;
    font-weight: 100;
    box-shadow: 0 5px 8px -2px var(--shadow-color-ultralight);
    width: 160px;
    height: 35px;
    border-radius: 50px;
    transition: 0.3s ease;
    cursor: pointer;
}

.save-button:hover {
    font-size: 20px;
    background-color: var(--corporate-color-13);
    box-shadow: 0 5px 8px -2px var(--shadow-color-light);
}

.show-more-tops-button {
    height: 3.5rem;
    width: 3.5rem;
    border-radius: 4rem;
    background-color: var(--corporate-color-13-light4);
    transition: 0.3s ease;
}
.show-more-tops-button:hover {
    background-color: var(--corporate-color-13-light);
}

.topbar-button {
    color: white;
    transition: 0.2s ease;
}

.topbar-button:hover {
    color: var(--corporate-color-7);
}

.max-min-button {
    padding-right: 8;
    padding-left: 8;
}

.show-history-button {
    color: var(--corporate-color-1);
    position: relative;
    top: -8px;
    height: 24px;
    background-color: var(--white-opac);
    box-shadow: 0 3px 5px -2px var(--shadow-color-light);
}

.show-history-button:hover {
    color: var(--corporate-color-2);
    background-color: var(--corporate-color-13-light2);
}

.hide-history-button {
    color: var(--corporate-color-1);
    position: relative;
    top: -8px;
    height: 24px;
    background-color: white;
    box-shadow: 0 3px 5px -2px var(--shadow-color-light);
}

.hide-history-button:hover {
    color: white;
    background-color: var(--corporate-color-2-light);
}

.add-tops-button {
    position: absolute;
    top: calc(100% - 50px - 1em);
    left: calc(100% - 249px - 1em);
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--corporate-color-1-light3);
    color: white;
    font-size: 16px;
    padding-right: 9px;
    box-shadow: 0 5px 8px -2px var(--shadow-color-normal);
    width: 240px;
    height: 50px;
    border-radius: 50px;
    transition: 0.3s ease;
    cursor: pointer;
    animation: fader 0.5s ease;
}

.add-tops-button:hover {
    font-size: 18px;
    background-color: var(--corporate-color-13-light3);
    box-shadow: 0 5px 8px -2px var(--shadow-color-normal);
}

.new-save-button-container {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: flex-end;
    position: absolute;
    bottom: 12px;
    right: 12px;
}

.new-save-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--corporate-color-2);
    color: white;
    font-size: 16px;
    box-shadow: 0 5px 8px -2px var(--shadow-color-light);
    border-radius: 50px;
    transition: 0.3s ease;
    cursor: pointer;
    padding: 18;
    animation: fader 0.5s ease;
}

.new-save-button:hover {
    background-color: var(--corporate-color-13);
    box-shadow: 0 5px 8px -2px var(--shadow-color-normal);
    transform: scale(1.15);
    transform-origin: bottom right;
}

/* ELSE */

.dashboard-screen-upper-topbar {
    display: flex;
    align-items: center;
    height: 60px;
    background-color: var(--corporate-color-1);
    padding-left: 24px;
    padding-right: 24px;
    z-index: 1;
}

.border-radius-shadow {
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.dashboard-screen-upper-topbar .label {
    color: var(--corporate-color-6);
}

.dashboard-screen-lower-topbar {
    display: flex;
    align-items: center;
    height: 60px;
    box-shadow: 0 5px 8px -2px var(--corporate-color-1-light4);
    padding-left: 24px;
    padding-right: 24px;
    z-index: 1;
    background-color: white;
    transition: 0.3s ease;
}

.dashboard-screen-filter-banner {
    display: flex;
    align-items: center;
    padding-left: 24px;
    padding-right: 24px;
    padding-top: 12px;
    animation: slider 0.7s ease;
    background-color: white;
    z-index: 0;
}

.dashboard-screen-filter-banner .subrow-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.dashboard-screen-filter-banner .subrow-container .subrow {
    display: flex;
    flex-direction: column;
    margin-right: 4px;
}

.protocol-screen-upper-topbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 60px;
    background-color: var(--corporate-color-1);
    padding-left: 32px;
    padding-right: 32px;
}

.protocol-screen-filterbanner-container {
    display: flex;
    justify-content: center;
    box-shadow: 0 7px 10px -2px var(--shadow-color-light);
    background-color: white;
    border-radius: var(--space-normbig);
}

.protocol-screen-filter-banner {
    display: flex;
    justify-content: space-between;
    padding: 12px;
    z-index: 0;
}

.protocol-screen-filter-banner .subrow-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
}

.protocol-screen-filter-banner .subrow-container .subrow {
    display: flex;
    flex-direction: column;
}

.meeting-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: white;
    border-radius: var(--space-normbig);
    margin: 12 24;
    padding: var(--space-norsmall);
    box-shadow: 0 5px 8px -2px var(--corporate-color-1-light4);
}

.meeting-area {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 30px;
    font-weight: 100;
    color: var(--corporate-color-2);
}

/* TOPS */

.top-row {
    display: flex;
    flex-direction: column;
}

.top-row .top-hierarchy {
    padding: var(--space-small);
    min-width: 200px;
    background-color: var(--corporate-color-5l);
    border-top-left-radius: var(--space-normbig);
    border-top-right-radius: var(--space-normbig);
}

.top-row .top-hierarchy-headline {
    padding: var(--space-small);
    min-width: 200px;
    background-color: var(--corporate-color-3);
    color: white;
    border-top-left-radius: var(--space-normbig);
    border-top-right-radius: var(--space-normbig);
}

.top {
    margin: var(--space-small);
    gap: var(--space-small);
}

.top .previous .row {
    gap: var(--space-small);
}

.top .latest {
    gap: var(--space-small);
    align-items: flex-start;
}

.top .previous {
    color: var(--color-2);
    animation: opac 1s ease;
}

.top .top-type-badge {
    height: 30px;
    width: 30px;
    background-color: darkgray;
    color: white;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    cursor: default;
}

.top .hoverscale:hover {
    transition: 0.4s ease;
    transform: scale(1.2);
}

.top .top-type-hover-effect {
    border: 1px solid transparent;
    transition: 0.3s ease;
}

.top .top-type-hover-effect:hover {
    box-shadow: 0px 0px 3px 0 var(--shadow-color-ultralight);
    transition: 0.4s ease;
    transform: scale(1.2);
}

.top .top-type-badge-headline {
    display: flex;
    height: 30px;
    width: 30px;
    background-color: var(--corporate-color-3);
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    cursor: default;
    color: white;
}

.top .previous .top-type-badge {
    cursor: default;
    opacity: 0;
}

.top .top-business-id {
    margin-bottom: var(--space-small);
    cursor: default;
}

.top-search-row .checkbox {
    cursor: pointer;
    height: var(--space-normal);
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    right: var(--space-normal);
}

.dashboard-top-button-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 12px;
    padding-right: 12px;
    background-color: var(--corporate-color-5l);
    height: 30px;
    border-top-left-radius: var(--space-normbig);
    border-top-right-radius: var(--space-normbig);
}

.dashboard-meeting-button-row {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color: var(--corporate-color-2);
    height: 30px;
    overflow: hidden;
    padding-left: 12px;
    border-top-left-radius: var(--space-normal);
    border-top-right-radius: var(--space-normal);
}

.assoc-button-container {
    display: flex;
    justify-content: flex-end;
    position: relative;
}

.assoc-button {
    justify-content: flex-end;
    color: var(--corporate-color-1);
}
.dissoc-button {
    color: var(--corporate-color-10);
}

.top-search-row .checkbox input {
    margin-left: var(--space-small);
    height: var(--space-normal-row);
    width: var(--space-normal-row);
}

/* Project Phase */

.project-phase {
    background-color: white;
    border-radius: var(--space-big);
    box-shadow: 0 5px 8px -2px var(--shadow-color-light);
    overflow: hidden;
    margin-bottom: var(--space-normbig);
    padding: var(--space-normal);
}

.project-phase input {
    border: 1px solid var(--color-3);
}

/* OLD */

.centerContainer {
    height: 100vh;
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0vh;
    right: 30vw;
}

.centerBox {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40vw;
    height: 65vh;
    border-style: none;
    border-radius: 35px;
    border: 1px solid var(--color-3);
    box-shadow: 160px -30px 10px 0px var(--corporate-color-7-light3),
        -160px 30px 10px 0px var(--corporate-color-2-light2),
        0px 0 30px var(--corporate-color-1-light);
    transition: 0.7s ease;
    animation: modal-scaler 1s ease;
    background-color: white;
}

.centerBox input {
    border: 1px solid var(--corporate-color-1-light4);
    margin-top: 6px;
    transition: 0.2s ease;
}

.centerBox input:hover {
    border: 1px solid var(--corporate-color-1-light);
}

.centerBox input:focus {
    border: 1px solid var(--corporate-color-1);
}

.button-blue {
    height: 35px;
    width: 100%;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    color: var(--corporate-color-2);
    border-color: var(--corporate-color-2);
    margin-bottom: 1px;
}

.button-quiet {
    height: 35px;
    width: 100%;
    font-weight: bold;
    font-size: 15px;
    text-align: center;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    cursor: pointer;
    border-width: 0;
    font-weight: normal;
    margin-bottom: 1px;
}

.button-quiet:hover {
    border-width: 1px;
}

.dashboard-toggle {
    width: 175px;
    color: var(--corporate-color-7);
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 50px;
}

.dashboard-toggle:hover {
    border: none;
    color: white;
    background-color: var(--corporate-color-5l);
    cursor: pointer;
}

.top-menu-container {
    height: 1;
    width: 1;
    position: relative;
}

.top-menu {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    background-color: var(--corporate-color-1);
    width: fit-content;
    padding: 3px;
    margin-top: 1px;
    border-radius: var(--space-normbig);
    box-shadow: 0 5px 8px -2px var(--corporate-color-1-light4);
    z-index: 2;
    animation: menu-shuffle 0.4s ease;
}

.top-menu button {
    color: white;
}

.top-menu-button {
    transition: 0.4s ease;
}
.top-menu-button:hover {
    transform: scale(1.2);
    background-color: transparent;
}

/* LOADING ANIMATION */

.loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 50vh;
}

.loader {
    border: 3px solid transparent;
    border-top: 3px solid var(--corporate-color-1);
    border-radius: 50%;
    width: 100px;
    height: 100px;
    margin-left: 81px;
    animation: spin 1.5s linear infinite;
}

.loader2 {
    position: relative;
    right: 93px;
    border: 3px solid transparent;
    border-top: 3px solid var(--corporate-color-7);
    border-radius: 50%;
    width: 75px;
    height: 75px;
    animation: spin 2s linear infinite;
}

.mini-loader-container {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 25px;
}

.mini-loader {
    border: 1px solid transparent;
    border-top: 1px solid var(--corporate-color-1);
    border-radius: 50%;
    width: 22px;
    height: 22px;
    animation: spin 1.5s linear infinite;
}

.mini-loader2 {
    position: relative;
    right: 20px;
    border: 1px solid transparent;
    border-top: 1px solid var(--corporate-color-3);
    border-radius: 50%;
    width: 16px;
    height: 16px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.dashboard-message {
    font-size: 30px;
    font-weight: 100;
    color: var(--corporate-color-1);
    text-align: center;
    margin-top: 190px;
}

.attachments-container .attachment {
    display: flex;
    align-items: center;
    height: 25px;
    background-color: white;
    padding: 5px;
    justify-content: space-between;
    overflow: hidden;
}

.protocol-notice {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 25vh;
    margin-bottom: 30vh;
    font-size: 30px;
    font-weight: 100;
    color: var(--corporate-color-2);
}

.attachments-container {
    border-radius: var(--space-small);
    overflow: hidden;
    margin-bottom: 14px;
}

.hoverwienrover {
    transition: 0.3s ease;
    cursor: pointer;
}

.hoverwienrover:hover {
    background-color: var(--corporate-color-5l);
}

.dashboard-area {
    display: flex;
    border-radius: 30px;
    margin-left: 6px;
    margin-right: 6px;
    margin-bottom: 6px;
    padding: 18px;
    padding-bottom: 24px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    border-top: none;
    max-width: 100%;
}

.dashboard-divider {
    border-right: 1px solid var(--corporate-color-1-light2);
    margin-left: 24px;
    margin-right: 24px;
    box-shadow: 0px 0px 10px 0px var(--corporate-color-1);
}

.meeting-search-row {
    color: red;
}

.ion-icon {
    --ionicon-stroke-width: 16px;
}

.project-img-field {
    flex: 1;
    flex-basis: 400px;
}

.org-img-field {
    flex: 1;
    flex-basis: 350px;
}

/* Badges, Indicators, messages */

.list-indicator-container {
    flex: 1;
    display: flex;
    justify-content: flex-end;
}

.list-indicator-foundation {
    width: 150px;
    height: 30px;
    background-color: white;
    border-radius: 15px;
    overflow: hidden;
    margin-right: var(--space-small);
    margin-left: var(--space-small);
}

.list-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    color: var(--corporate-color-1);
    background-color: var(--corporate-color-5l);
    border-radius: 15px;
    width: 150px;
    height: 30px;
}

.blocked-badge {
    color: var(--corporate-color-12);
    padding: 3px 5px 3px 5px;
    margin-left: 12px;
    font-size: 10px;
    border-radius: 10px;
    border: 1px solid var(--corporate-color-12);
}

.user-badge {
    color: var(--corporate-color-13);
    padding: 3px 5px 3px 5px;
    margin-left: 12px;
    font-size: 10px;
    border-radius: 10px;
    border: 1px solid var(--corporate-color-13);
}

.invited-badge {
    color: var(--corporate-color-3);
    padding: 3px 5px 3px 5px;
    margin-left: 12px;
    font-size: 10px;
    border-radius: 10px;
    border: 1px solid var(--corporate-color-3);
}

.date-badge {
    display: flex;
    justify-content: center;
    color: var(--corporate-color-2);
    background-color: white;
    padding: 5px 8px 5px 8px;
    border-radius: 15px;
    border: 2px solid var(--corporate-color-2);
    font-weight: bold;
    min-width: 120px;
}

.opp-date-badge-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 6px 12px 6px 12px;
    border-radius: 50px;
    box-shadow: 0px 0px 7px 0px var(--corporate-color-1-light);
    background-color: white;
    min-width: 18ch;
}

.opp-date-badge-indicator {
    color: var(--corporate-color-2);
}

.opp-date-badge {
    color: var(--corporate-color-2-light);
}

.month-badge {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--corporate-color-2);
    padding: 5px 8px 5px 8px;
    border-radius: 15px;
    border: 2px solid var(--corporate-color-2);
    font-weight: bold;
    min-width: 120px;
    background-color: white;
    position: relative;
    right: 70px;
}

.meeting-badge {
    display: flex;
    justify-content: center;
    color: white;
    background-color: var(--corporate-color-2);
    padding: 3px 5px 3px 5px;
    border-radius: 15px;
    border: 1px solid var(--corporate-color-2);
    min-width: 115px;
    transition: 0.2s ease;
    cursor: pointer;
}

.meeting-badge:hover {
    background-color: var(--corporate-color-4);
    border-color: var(--corporate-color-4);
}

.date-badge-light {
    display: flex;
    justify-content: center;
    color: var(--corporate-color-2);
    background-color: white;
    padding: 3px 5px 3px 5px;
    border-radius: 15px;
    border: 1px solid var(--corporate-color-2);
    min-width: 115px;
}

.opp-date-badge-light {
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--corporate-color-2);
    background-color: white;
    padding: 3px 5px 3px 5px;
    border-radius: 30px;
    border: 1px solid var(--corporate-color-1);
    width: 20ch;
    height: 30px;
    font-weight: normal;
    font-size: 15px;
}

.no-content-message {
    display: flex;
    flex: 1;
    justify-content: center;
    margin-top: 24px;
    color: var(--corporate-color-1);
    font-size: 20px;
    font-weight: 100;
}

/* Timeline */

.timeline-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: var(--space-normal-row);
}

.timeline-inner-container {
    display: flex;
    justify-content: center;
    flex: 1;
}

.timeline-line {
    height: fit-content;
    width: 0;
    border: 1px solid var(--corporate-color-2);
    padding-bottom: 30px;
}

.timeline-row {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 300px;
    position: relative;
    right: 150px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.timeline-row-line {
    height: 0;
    width: 30px;
    border-bottom: 1px solid var(--corporate-color-2);
}

.detailed-meeting-container {
    display: flex;
    flex: 1;
    justify-content: center;
    color: var(--corporate-color-2);
    border-top: 1px solid var(--corporate-color-2);
    margin-top: 6px;
    padding-top: 6px;
}

/* Scorecard */

.scorecard-linked-items-container {
    display: flex;
    justify-content: space-around;
    margin-top: 12px;
}

.scorecard-linked-items-container .headline {
    color: var(--corporate-color-1);
    font-size: 20px;
    cursor: pointer;
    font-weight: 100;
    margin-top: 6px;
}
.scorecard-linked-items-container .count {
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: var(--corporate-color-14);
    font-size: 24px;
    font-weight: 100;
    cursor: pointer;
    height: 40px;
    width: 40px;
    border-radius: 50px;
}

.scorecard-linked-items-container .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1;
}

.scorecard-linked-items-container .column2 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-radius: 30px;
    cursor: pointer;
    padding: 15px;
    box-sizing: border-box;
}

.scorecard-linked-items-container .hoverwienrover {
    border: 1px solid transparent;
    transition: 0.2s ease;
}

.scorecard-linked-items-container .hoverwienrover:hover {
    background-color: var(--corporate-color-5l);
}

.scorecard-linked-items-container .top-type-badge {
    height: 30px;
    width: 30px;
    background-color: darkgray;
    border-radius: 15px;
    align-items: center;
    justify-content: center;
    cursor: default;
    font-size: 15px;
}

.opp-timeline-elements-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.opp-timeline-element {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
}

.opp-timeline-status-row {
    display: flex;
    align-items: center;
    position: relative;
    top: -19px;
}

.arrow-indicator {
    position: absolute;
    font-weight: 100;
    margin-left: 40px;
    color: var(--corporate-color-2);
}

.arrow-base {
    width: 0;
    height: 100%;
    border-left: 1px solid var(--corporate-color-2);
}

.arrow-head-left {
    width: 0;
    height: 12;
    transform: rotate(45deg);
    border-left: 1px solid var(--corporate-color-2);
    position: absolute;
    bottom: -2px;
    margin-left: 8px;
}

.arrow-head-right {
    width: 0;
    height: 12px;
    transform: rotate(-45deg);
    border-left: 1px solid var(--corporate-color-2);
    position: absolute;
    bottom: -2px;
    margin-right: 8px;
}

/* Animations */

@keyframes scaler {
    0% {
        transform: scale(0.5);
    }
    100% {
        transform: scale(1);
    }
}

.scale-animation {
    animation: scaler 0.4s ease;
}

@keyframes scaleX {
    0% {
        transform: scaleX(0);
    }
    100% {
        transform: scaleX(1);
    }
}

.scale-x {
    animation: scaleX 0.5s ease;
    transform-origin: left;
}

@keyframes fader {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.fader {
    animation: fader 0.6s ease;
}

@keyframes screen-fader {
    0% {
        opacity: 0.9;
        transform: scale(0.99);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.screen-fader {
    animation: screen-fader 0.7s ease;
}

.hover-effect {
    font-size: 17px;
    transition: 0.2s ease;
}

.hover-effect:hover {
    font-size: 18px;
}

@keyframes menu-shuffle {
    from {
        transform: scale(0%) translateX(50%) translateY(-50%);
    }
    to {
        transform: scale(100%) translateX(0%) translateY(0%);
    }
}

@keyframes opac {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes slider {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0%);
    }
}

@keyframes slider-search-half {
    from {
        transform: translateX(40%);
        opacity: 0;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes slider-search {
    from {
        transform: translateX(80%);
        opacity: 0;
    }
    to {
        transform: translateX(0%);
        opacity: 1;
    }
}

@keyframes top-scaler {
    0% {
        transform: scaleX(0.25) scaleY(0.75);
        opacity: 0;
    }
    100% {
        transform: scaleX(1) scaleY(1);
        opacity: 1;
    }
}

.top-scaling {
    animation: top-scaler 0.5s ease;
}

@keyframes modal-scaler {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes background-opacer {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes toast-animation {
    0% {
        transform: translateX(10vw);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

/* Generic */

.background-color-var-cc13-hover:hover {
    background-color: var(--corporate-color-13);
}

.background-color-var-cc13-light {
    background-color: var(--corporate-color-13-light);
}

.background-color-var-cc13-light-hover:hover {
    background-color: var(--corporate-color-13-light);
}

.background-color-var-cc14-light-hover:hover {
    background-color: var(--corporate-color-14-light);
}

/* Datepicker */

.react-datepicker {
    box-shadow: 0 0.5rem 1.5rem -2px var(--shadow-color-light);
    border-radius: 20px;
    border-color: var(--color-3);
    overflow: hidden;
    position: absolute;
}

.react-datepicker__header {
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    background-color: white;
    border-bottom: 1px solid var(--corporate-color-2);
}

.react-datepicker__navigation--next {
    transform: scale(1);
}

.react-datepicker__navigation--previous {
    transform: scale(1);
}

/* Menu */

.dashboard-menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    background-color: white;
    position: fixed;
    top: 2.75rem;
    right: 24px;
    padding: 3px;
    width: fit-content;
    border-radius: 1.25rem;
    border: 1px solid var(--color-3);
    box-shadow: 0 5px 8px 0px var(--shadow-color-light);
    z-index: 1000;
}

.dashboard-menu-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
}

.hover-scale {
    transition: 0.3s ease;
}

.hover-scale:hover {
    transform: scale(1.05);
}

.slide-in {
    animation: slide-in-y 0.6s ease;
}

@keyframes slide-in-y {
    from {
        transform: translateY(7.5rem) scale(1.05);
    }
    to {
        transform: translateY(0rem) scale(1);
    }
}
