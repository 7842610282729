.modal-backdrop-mobile {
    display: none;
}
.modal-backdrop-mobile.open {
    display: flex;
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
}

.modal-container-mobile {
    width: 100vw;
    height: 100%;
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 5000;
    background-color: white;
}
