/* Chart and Board Vars */

:root {
    --cw-line-width: 40px;
    --cw-indicator-width: 80px;
    --cw-border-width: 1px;
}

.charts-area {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.charts-area .headline {
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--corporate-color-4);
    font-size: 22;
    font-weight: 100;
    color: var(--corporate-color-2);
    margin-left: 24;
    margin-right: 24;
    cursor: default;
}

.charts-area .layout {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.charts-area .opp-charts {
    display: flex;
    align-items: flex-start;
}

.charts-area .doughnut-container {
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    width: 340px;
}

.charts-area .inner-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20%;
    right: 20%;
    bottom: 20%;
    left: 20%;
    padding: 1.25em 0;
    line-height: 120%;
    overflow: hidden;
}

.charts-area .inner-text .title {
    font-size: 23px;
    color: var(--corporate-color-2);
    margin-top: 9;
    font-weight: 100;
}

.charts-area .inner-text .info {
    font-size: 23px;
    color: var(--corporate-color-1);
    margin-top: 15;
}

.charts-area .inner-text .title-critical {
    font-size: 19px;
    color: var(--corporate-color-12);
    margin-top: 20;
    font-weight: 100;
}

.charts-area .inner-text .info-critical {
    font-size: 19px;
    color: var(--corporate-color-12);
    margin-top: 5;
}

.charts-area .chart-legend {
    display: flex;
    align-items: center;
    margin-bottom: 24;
    justify-content: space-between;
    width: 100%;
}

.charts-area .legend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.charts-area .legend-item {
    display: flex;
    align-items: center;
    height: 40px;
    margin-left: 5vw;
    margin-right: 5vw;
}

.charts-area .legend-color {
    width: 27;
    height: 27;
    border-radius: 15px;
}

.charts-area .legend-title {
    color: var(--corporate-color-1);
    border-bottom: 1px solid var(--corporate-color-2);
    cursor: default;
    font-size: 17;
}

.charts-area .legend-label {
    color: var(--corporate-color-1);
    margin-left: 10;
    cursor: default;
    font-size: 17;
}

.charts-area .tables {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.inner {
    margin-bottom: 150px;
}

.inner .actions-headline {
    color: var(--corporate-color-1);
    font-size: 20;
}

.charts-area .actions-chart {
    display: flex;
    justify-content: center;
    width: 95vw;
    margin-top: 36;
    margin-bottom: 48;
}

.charts-area .actions-chart .inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60vw;
}

.inner p {
    color: var(--corporate-color-1);
    font-size: 17;
    margin-bottom: 20;
}

.table-container {
    display: flex;
    flex-direction: column;
    padding: 12px;
    border-radius: var(--space-normbig);
    width: 96%;
    margin-bottom: 10px;
    margin-top: 20px;
    cursor: default;
    box-shadow: 0 5px 8px -2px var(--corporate-color-1-light4);
    background-color: white;
}

.table-container .table-header {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom-style: solid;
    border-bottom-width: 1px;
    border-bottom-color: var(--corporate-color-2);
    margin-bottom: 12;
    padding-bottom: 3;
}

.table-container .header-entry-left {
    display: flex;
    justify-content: flex-start;
    font-size: 17px;
    width: 33%;
    color: var(--corporate-color-2);
    font-weight: 100;
}

.table-container .header-entry-middle {
    display: flex;
    justify-content: center;
    font-size: 17px;
    width: 33%;
    font-weight: 100;
}

.table-container .footer-entry-middle {
    display: flex;
    border-radius: 12px;
    justify-content: center;
    font-size: 17px;
    width: 33%;
    transition: 0.1s ease;
    cursor: pointer;
}
.table-container .footer-entry-middle:hover {
    font-weight: 500;
    color: white;
    background-color: darkgray;
}

.table-container .header-entry-right {
    display: flex;
    justify-content: flex-end;
    font-size: 17px;
    width: 33%;
    color: var(--corporate-color-2);
    font-weight: 100;
}

.table-container .table-row {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 6px;
    margin-bottom: 6px;
}

.table-container .table-entry-left {
    display: flex;
    justify-content: flex-start;
    font-size: 17px;
    width: 33%;
    overflow: hidden;
    font-weight: 100;
}

.table-container .table-entry-middle {
    display: flex;
    justify-content: center;
    font-size: 17px;
    border-radius: 12px;
    width: 33%;
    transition: 0.1s ease;
    cursor: pointer;
}

.table-container .table-entry-middle:hover {
    font-weight: bold;
    color: white;
    background-color: darkgray;
}

.global-dashboard-area .table-container .table-entry-middle:hover {
    font-weight: bold;
    color: black;
    background-color: var(--corporate-color-5l);
}

.table-container .table-entry-right {
    display: flex;
    justify-content: flex-end;
    font-size: 17px;
    width: 33%;
    font-weight: 100;
}

.table-container .footer-entry-right {
    display: flex;
    justify-content: flex-end;
    font-size: 17px;
    width: 33%;
    color: var(--corporate-color-11);
    font-weight: 100;
}

.table-container .table-footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--corporate-color-11);
    padding-top: 3px;
    margin-top: 3px;
}

.table-container .table-footer-critical {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Kanban */

.actions-kanban-container {
    width: 100%;
    height: 90vh;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    margin-top: 24px;
    padding: 0 24px;
    box-sizing: border-box;
}

.actions-kanban-container::-webkit-scrollbar {
    display: none;
}

.actions-kanban-container .board-element {
    display: inline-block;
    width: 25vw;
    min-width: 400px;
    height: 82.5vh;
    background-color: white;
    box-shadow: 0 5px 8px -2px var(--corporate-color-1-light4);
    border-radius: var(--space-normbig);
    text-align: center;
    margin: 24px;
    overflow-y: hidden;
    overflow-x: hidden;
}

/* info */
.board-element .header {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    margin: 6 var(--space-small) 6 6;
}

.board-element .content {
    height: inherit;
    overflow: scroll;
}

.content::-webkit-scrollbar {
    display: none;
}

.board-element .header .count {
    height: 27px;
    min-width: 27px;
    border-radius: 20px;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    font-size: 16px;
    background-color: var(--corporate-color-7);
    color: var(--corporate-color-1);
    margin-right: 9;
}

.header .status {
    font-size: 20px;
    font-weight: 100;
    color: var(--corporate-color-1);
}

@keyframes drop-scaler {
    0% {
        transform: scale(0.8);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.prev-drop-indicator {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 20px;
    left: 0px;
    width: 33%;
    height: 100%;
    background-image: linear-gradient(to right, var(--corporate-color-14-light), transparent);
    border-radius: var(--space-normal) 0 0 var(--space-normal);
    box-sizing: border-box;
}

/* .prev-drop-indicator:hover {
    background-color: var(--corporate-color-10-light);
} */

.next-drop-indicator {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 20px;
    right: 0px;
    width: 33%;
    height: 100%;
    background-image: linear-gradient(to right, transparent, var(--corporate-color-13-light));
    border-radius: 0 var(--space-normal) var(--space-normal) 0;
    box-sizing: border-box;
}

.drop-info {
    color: white;
    text-shadow: 0 0 10px black, 0 0 10px black, 0 0 10px black;
    pointer-events: none;
}

/* Gantt */

.gantt-chart {
    width: 100%;
    height: 85vh;
    overflow: scroll;
    white-space: nowrap;
    margin-top: 36px;
    border-radius: var(--space-normbig);
}

.gantt-chart-overview {
    width: calc(100%-24px);
    height: fit-content;
    overflow: scroll;
    white-space: nowrap;
    margin-top: 36px;
    margin-left: 24px;
    box-sizing: border-box;
}

.gantt-chart::-webkit-scrollbar {
    display: none;
}

.gantt-content {
    display: flex;
    flex-direction: column;
    border-right: 7px solid darkgray;
}

.phases-rows {
    display: flex;
    flex-direction: column;
}

.gantt-chart .year {
    font-size: 28px;
    font-weight: 100;
    margin-top: 24px;
    color: var(--color-2);
    position: relative;
    left: 6px;
    bottom: 5px;
    width: fit-content;
    z-index: -1;
}

.gantt-buttons-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: calc(100% - 57px);
    position: absolute;
}

.gantt-buttons {
    display: flex;
    align-items: center;
    position: relative;
    width: fit-content;
    border-radius: 20px;
}

.cws-row {
    display: flex;
    margin-bottom: 6px;
}

.cw-container {
    display: flex;
    align-items: center;
}

.cw-line {
    display: flex;
    align-items: center;
    width: var(--cw-line-width);
    border-bottom: 1px solid var(--color-2);
    height: 0;
}

.cw-start {
    height: 7px;
    width: 7px;
    background-color: var(--color-2);
    border-radius: 5px;
    position: relative;
    top: 0.5;
    right: 3.5;
    z-index: -1;
}

.cw-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    width: var(--cw-indicator-width);
    height: 22px;
    border: var(--cw-border-width) solid var(--color-2);
    color: var(--corporate-color-1);
    border-radius: 20px;
    box-shadow: 0 5px 8px -2px var(--corporate-color-1-light4);
    background-color: white;
}

.top-count {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40;
    width: 40;
    background-color: var(--corporate-color-7);
    font-size: 20px;
    color: var(--corporate-color-1);
    font-weight: 100;
    margin-right: 12px;
    border-radius: 20px;
}

.top-count-overview {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 22px;
    min-width: 22px;
    background-color: var(--corporate-color-7);
    font-size: 16px;
    color: var(--corporate-color-1);
    margin-right: 12px;
    border-radius: 20px;
    padding: 0 6px;
    font-weight: bold;
}

.gantt-phase {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15 0 5;
    height: 50px;
    border-radius: 30px;
    margin-top: 20px;
    box-sizing: border-box;
    box-shadow: 0 5px 8px -2px var(--corporate-color-1-light4);
    transition: 0.3s ease;
    overflow: hidden;
}

.gantt-phase-overview {
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 5 0 5;
    height: 30px;
    border-radius: 30px;
    margin-top: 20px;
    box-sizing: border-box;
    box-shadow: 0 5px 8px -2px var(--corporate-color-1-light4);
    transition: 0.3s ease;
    overflow: hidden;
}

.gantt-phase:hover {
    cursor: pointer;
    background-color: var(--corporate-color-14-light3);
    box-shadow: 0 5px 8px -2px var(--shadow-color-normal);
}

.gantt-phase-overview:hover {
    cursor: pointer;
    background-color: var(--corporate-color-14-light3);
    box-shadow: 0 5px 8px -2px var(--shadow-color-normal);
}

.phase-name {
    font-size: 18px;
    color: var(--corporate-color-1);
    font-weight: 100;
    cursor: pointer;
}

.phase-name-overview {
    font-size: 16px;
    color: var(--corporate-color-1);
    font-weight: 100;
    cursor: pointer;
}

.milestone-indicator {
    height: 50px;
    width: 50px;
    margin-top: 20px;
    border-radius: 5px;
    background-color: var(--corporate-color-2);
    color: var(--corporate-color-7);
    transform: rotate(45deg);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
    transition: 0.3s ease;
}

.milestone-indicator:hover {
    cursor: pointer;
    color: var(--corporate-color-13);
    box-shadow: 0 0 12px -2px var(--corporate-color-13);
}

.milestone-indicator-overview {
    height: 28px;
    width: 28px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: var(--corporate-color-2);
    color: var(--corporate-color-7);
    transform: rotate(45deg);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 17px;
    transition: 0.3s ease;
}

.milestone-indicator-overview:hover {
    cursor: pointer;
    box-shadow: 0 0 12px -2px var(--corporate-color-13);
}

.final-milestone-indicator-overview {
    height: 32px;
    width: 32px;
    margin-top: 10px;
    border-radius: 5px;
    background-color: var(--corporate-color-2);
    color: var(--corporate-color-13);
    transform: rotate(45deg);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    transition: 0.3s ease;
}

.final-milestone-indicator-overview:hover {
    cursor: pointer;
    box-shadow: 0 0 12px -2px var(--corporate-color-13);
}

.milestone-indicator-modal {
    height: 50px;
    width: 50px;
    margin-top: 20px;
    border-radius: 5px;
    background-color: var(--corporate-color-2);
    color: var(--corporate-color-13);
    box-shadow: 0 0 12px -2px var(--corporate-color-13);
    transform: rotate(45deg);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
}
