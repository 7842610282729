.print-modal-content-container {
    display: flex;
    flex-direction: row;
    flex: 1;
}
.print-modal-mail-form {
    display: flex;
    flex-direction: column;
    flex: 1;
}
.print-modal-pdf-viewer {
    display: flex;
    flex: 1.5;
}
