.screen-container {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-height: 100vh;
    background-color: rgba(248, 247, 248);
}
.topbar-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    box-sizing: border-box;
}
.topbar-container > * {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
}
.content-container {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
}
