/* VARS */
:root {
    --font-family: 'Helvetica', sans-serif;
    --font-size-smaller: 0.5em;
    --font-size-small: 0.7em;
    --font-size-normal: 1em;
    --font-size-large: 1.3em;
    --font-size-big: 2em;
    --font-size-extra-big: 3em;
    --font-size-mobile-small: 1.5em;
    --font-size-mobile-smallium: 2em;
    --font-size-mobile-medium: 2.5em;
    --font-size-mobile-normal: 3em;
    --font-size-mobile-normla: 3rem;
    --font-size-mobile-large: 4em;
    --font-size-mobile-big: 5em;
    --font-size-mobile-bigmac: 5.5em;
    --font-size-mobile-whopper: 6em;
    --space-small: 0.5em;
    --space-norsmall: 0.75em;
    --space-normal: 1em;
    --space-normbig: 1.25em;
    --space-normal-row: 1.5em;
    --space-large-row: 1.75em;
    --space-big: 2em;
    --control-height: 2.3em;
    --control-label-pos: 0.2em;
    --color-1: rgb(70, 70, 70);
    --color-2: darkgray;
    --color-3: #e0e0e0;
    --color-3-light: #e0e0e075;
    --color-4: #fff;
    --color-success: green;
    --color-error: #ff0000;
    --color-error-light: #ff000073;
    --color-info: blue;
    --white-opac: rgba(256, 256, 256, 0.75);
    --white-opac2: rgba(256, 256, 256, 0.95);
    --shadow-color-dark: rgba(0, 0, 0, 0.75);
    --shadow-color-normal: rgba(0, 0, 0, 0.5);
    --shadow-color-light: rgba(0, 0, 0, 0.3);
    --shadow-color-ultralight: rgba(0, 0, 0, 0.17);
    --corporate-color-1: #003459;
    --corporate-color-1-light: #00345966;
    --corporate-color-1-light2: #00345980;
    --corporate-color-1-light3: #003459e6;
    --corporate-color-1-light4: #00345926;
    --corporate-color-1-light5: #0034594d;
    --corporate-color-2: #005980;
    --corporate-color-2-light: #0059808c;
    --corporate-color-2-light2: #005980bf;
    --corporate-color-2-light3: #00598033;
    --corporate-color-3: #007ea7;
    --corporate-color-3-light: #007ea726;
    --corporate-color-3-light2: #007ea780;
    --corporate-color-4: #64a6c1;
    --corporate-color-5: #96bace;
    --corporate-color-5l: #96bace5e;
    --corporate-color-5-light2: #96bacebf;
    --corporate-color-6: #c7cedb;
    --corporate-color-6-light: #c7cedbbf;
    --corporate-color-6-light2: #c7cedbf7;
    --corporate-color-6-ultralight: #c7cedb40;
    --corporate-color-6-modal: #e3e7ed;
    --corporate-color-7: #ffd166;
    --corporate-color-7-light: #ffd16626;
    --corporate-color-7-light2: #ffd1668c;
    --corporate-color-7-light3: #ffd166bf;
    --corporate-color-7-light4: #ffd1669e;
    --corporate-color-8: #e3d0a1;
    --corporate-color-9: #a5668b;
    --corporate-color-10: #70163c;
    --corporate-color-10-light: #70163c80;
    --corporate-color-10-light2: #70163cbf;
    --corporate-color-10-light3: #70163c26;
    --corporate-color-11: #0ea700e1;
    /* Top badge colors a, s, d */
    --corporate-color-12: #ff3a20;
    --corporate-color-13: #16db65;
    --corporate-color-13-light3: #16db65e6;
    --corporate-color-13-light2: #16db65bf;
    --corporate-color-13-light2b: #16db6599;
    --corporate-color-13-light: #16db6580;
    --corporate-color-13-light4: #16db6540;
    --corporate-color-13-light5: #16db651a;
    --corporate-color-13-light6: #16db6526;
    --corporate-color-14: #0caadc;
    --corporate-color-14-ligh: #0caadce6;
    --corporate-color-14-lightb: #0caadc99;
    --corporate-color-14-light: #0caadc80;
    --corporate-color-14-light2: #0caadc40;
    --corporate-color-14-light3: #0caadc1a;
}

/* COMMON */

html {
    font-size: 16px;
}

body,
body * {
    font-family: var(--font-family);
    margin: 0;
}

.mobile-app-container {
    font-weight: 400;
    -webkit-tap-highlight-color: transparent;
}
.login-container-mobile {
    -webkit-tap-highlight-color: transparent;
}

p {
    cursor: default;
}

.fs3-5rem {
    font-size: 3.5rem;
}

button p {
    cursor: pointer;
    margin-left: 6px;
}

button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    border: none;
    border-radius: 20px;
    background-color: transparent;
    cursor: pointer;
    color: var(--corporate-color-1);
    height: var(--control-height);
    padding: var(--space-norsmall);
    transition: 0.2s ease;
}

button:hover {
    border: none;
    cursor: pointer;
}

textarea {
    padding-top: var(--space-small);
}

.mobile-textarea-height-60 .field .input {
    height: 60vh;
}

.mobile-textarea-height-50 .field .input {
    height: 50vh;
}

.mobile-textarea-height-40 .field .input {
    height: 40vh;
}

.mobile-textarea-height-25 .field .input {
    height: 25vh;
}

:focus,
:focus-visible,
:active {
    outline: none;
}

.pdf-viewer-mobile {
    border-radius: var(--space-small);
    flex: 1;
}

.offline-syncer {
    background-color: var(--color-3);
    border: 2px solid var(--color-error);
    border-radius: 10px;
    box-shadow: 0px 0px 20px 0px var(--shadow-color-light);
}

.mobile-app-container .input {
    border-radius: 2rem;
}

.input-mobile {
    margin-bottom: 2rem;
}

.input-mobile input {
    border: 3px solid var(--color-3);
    color: var(--color-1);
    height: 5rem;
    margin: 0 2rem 0 2rem;
    font-size: 3.5rem;
    font-weight: 400;
    transition: 0.3s ease;
}

.input-mobile textarea {
    border: 3px solid var(--color-3);
    color: var(--color-1);
    height: 15vh;
    margin: 0 2rem 0 2rem;
    font-size: 3.5rem;
    font-weight: 400;
    transition: 0.3s ease;
}

.field.input-mobile input {
    border-radius: 2rem;
    font-weight: 400;
}

.input-mobile input:focus {
    color: var(--corporate-color-1);
    border: 3px solid var(--color-2);
}

.field .label-mobile {
    font-size: 2rem;
    white-space: nowrap;
    margin: 0.5rem 0 0 2rem;
    background-color: var(--corporate-color-2-light2);
    padding: 0.35rem 1rem 0.35rem 1rem;
    border-radius: 4rem;
    color: white;
    width: fit-content;
}

.label-mobile {
    font-size: 2rem;
    white-space: nowrap;
    margin: 0.5rem 0 0 2rem;
    background-color: var(--corporate-color-2-light2);
    padding: 0.35rem 1rem 0.35rem 1rem;
    border-radius: 4rem;
    color: white;
    width: fit-content;
}

.field .dd-label-mobile {
    font-size: 2rem;
    white-space: nowrap;
    margin: 0.5rem 0 2rem 2rem;
    background-color: var(--corporate-color-2-light2);
    padding: 0.35rem 1rem 0.35rem 1rem;
    border-radius: 4rem;
    color: white;
    width: fit-content;
}

.dd-label-mobile {
    font-size: 2rem;
    white-space: nowrap;
    margin: 0.5rem 0 2rem 2rem;
    background-color: var(--corporate-color-2-light2);
    padding: 0.35rem 1rem 0.35rem 1rem;
    border-radius: 4rem;
    color: white;
    width: fit-content;
}

.field .no-left-margin {
    margin-left: 0;
}

.field.date-field.no-left-margin {
    margin-left: 0;
}
.field.date-field.mobile.no-left-margin {
    margin-left: 0;
}
.field.date-field.no-bottom-margin {
    margin-bottom: 0;
}

.field .date-label-mobile {
    font-size: 2rem;
    margin: 0.5rem 0 2rem 0;
    background-color: var(--corporate-color-2-light2);
    padding: 0.35rem 1rem 0.35rem 1rem;
    border-radius: 4rem;
    color: white;
    width: fit-content;
}

.date-label-mobile {
    font-size: 2rem;
    margin: 0.5rem 0 2rem 0;
    background-color: var(--corporate-color-2-light2);
    padding: 0.35rem 1rem 0.35rem 1rem;
    border-radius: 4rem;
    color: white;
    width: fit-content;
}

.margin-bottom {
    margin-bottom: 2rem;
}

.mobile-img-field {
    flex: 1;
    flex-basis: 350px;
    border: 3px solid var(--color-3);
    height: 40vh;
    margin: 0 2rem 0 2rem;
    border-radius: 2rem;
}

/* MODALS */

.modal-container-mobile {
    overflow: hidden;
    z-index: 5000;
    animation: modal-scaler 0.6s ease;
}

.modal-container-mobile .modal-content-container-mobile {
    height: 100%;
    width: 100vw;
    background-color: white;
    border-style: none;
    /* padding-top: var(--space-normal-row); */
    overflow-y: scroll;
    z-index: 5000;
}

.modal-container-mobile .modal-content-container-mobile .columns {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    flex-wrap: wrap;
    gap: var(--space-normal);
    flex: 1;
}

/* TOASTS */

@keyframes toast-animation-mobile {
    0% {
        transform: scaleX(0) scaleY(0.5) translateY(-10vh);
        opacity: 0;
    }
    100% {
        transform: scaleX(1) scaleY(1) translateY(0);
        opacity: 1;
    }
}

.toast-mobile.success {
    background-color: white;
    border: 2px solid var(--corporate-color-13);
    border-radius: 15rem;
    box-shadow: 0 0 3rem -2px var(--corporate-color-13);
    color: var(--corporate-color-13);
    animation: toast-animation-mobile 0.6s ease;
    z-index: 1000000;
}
.toast-mobile.spinner {
    background-color: white;
    border: 3px solid var(--corporate-color-3);
    border-radius: 15rem;
    box-shadow: 0 0 3rem -2px var(--corporate-color-3);
    color: var(--corporate-color-3);
    animation: toast-animation-mobile 0.6s ease;
    z-index: 1000000;
}
.toast-mobile.error {
    background-color: white;
    border: 2px solid var(--corporate-color-10);
    border-radius: 15rem;
    box-shadow: 0 0 3rem -2px var(--corporate-color-10);
    color: var(--corporate-color-10);
    animation: toast-animation-mobile 0.6s ease;
    z-index: 1000000;
}

.toast-mobile.hint {
    background-color: white;
    border: 2px solid var(--corporate-color-14);
    border-radius: 15rem;
    box-shadow: 0 0 3rem -2px var(--corporate-color-14);
    color: var(--corporate-color-14);
    animation: toast-animation-mobile 0.6s ease;
    z-index: 1000000;
}

/* Inputs */

.animated-searchbar-mobile {
    display: flex;
    align-items: center;
    animation: slider-search 0.7s ease;
    flex: 1;
}

.animated-searchbar-mobile input {
    box-sizing: border-box;
    height: 6.5rem;
    border-radius: 3rem;
    padding-left: 2rem;
    flex: 1;
    border: 1px solid var(--corporate-color-1-light);
    transition: 0.2s ease;
    font-size: 3rem;
}

.animated-searchbar-mobile input:hover {
    border: 1px solid var(--corporate-color-6-light);
    box-shadow: inset 0px 0px 3px 0px var(--shadow-color-normal);
}

.animated-searchbar-mobile input:focus {
    border: 1px solid var(--corporate-color-1-light2);
    box-shadow: inset 0px 0px 3px 0px var(--corporate-color-1-light);
}

/* Mobile */

.mobile-app-container {
    height: 100%;
    width: 100vw;
    overflow: hidden;
    position: fixed;
}

.mobile-screen-container {
    height: 100%;
    width: 100vw;
    overflow: scroll;
}

.mobile-logo-container {
    display: flex;
    flex: 1;
    justify-content: flex-start;
    align-items: center;
    height: 5rem;
}

.content-container-mobile {
    margin: 0 2vw 0 2vw;
}

/* Mobile buttons */

@keyframes fade-scale-mobile-buttons {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes pulse-type-indicator {
    0% {
        transform: scale(1);
    }
    25% {
        transform: scale(0.95);
    }
    50% {
        transform: scale(1.15);
    }
    100% {
        transform: scale(1);
    }
}

.fade-scale-mobile-buttons {
    animation: fade-scale-mobile-buttons 0.3s ease;
}

.fade-scale-type {
    animation: fade-scale-item-buttons 0.3s ease;
}

.pulse-type-indicator {
    animation: pulse-type-indicator 0.7s ease 0.7s;
}

.send-button {
    animation: pulse-type-indicator 0.7s ease;
}

.menu-button-row {
    z-index: 100;
}

.menu-button {
    position: absolute;
    left: 3rem;
    bottom: 3rem;
    height: 9rem;
    width: 9rem;
    border-radius: 6rem;
    color: var(--corporate-color-1);
    background-color: white;
    box-shadow: 0 0 2rem 0.25rem var(--shadow-color-light);
    z-index: 199;
    overflow: hidden;
    animation: back-button 0.6s ease;
}

.show-more-tops-button-mobile {
    height: 7rem;
    width: 7rem;
    border-radius: 6rem;
    color: var(--corporate-color-2);
    background-color: white;
    box-shadow: 0 0 1.25rem 0.25rem var(--shadow-color-light);
    z-index: 198;
    overflow: hidden;
    margin-top: 2rem;
}

.menu-button-raw {
    height: 9rem;
    width: 9rem;
    border-radius: 6rem;
    color: var(--corporate-color-1);
    background-color: white;
    box-shadow: 0 0 2rem 0.25rem var(--shadow-color-light);
    z-index: 199;
    overflow: hidden;
}

.open-pdf-button {
    position: absolute;
    bottom: 3rem;
    height: 9rem;
    width: 9rem;
    border-radius: 6rem;
    color: var(--corporate-color-1);
    background-color: white;
    box-shadow: 0 0 2rem 0.25rem var(--shadow-color-light);
    z-index: 199;
    overflow: hidden;
    animation: search-button 0.6s ease;
}

.logout-button-mobile {
    height: 9rem;
    width: 9rem;
    border-radius: 5rem;
    box-shadow: none;
    background-color: white;
}

@keyframes back-button {
    0% {
        opacity: 0;
        transform: scale(0.5) translateX(-100%) translateY(100%);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateX(0%) translateY(0%);
    }
}

.back-button {
    position: absolute;
    left: 3rem;
    bottom: 3rem;
    height: 9rem;
    width: 9rem;
    border-radius: 6rem;
    color: var(--corporate-color-1);
    background-color: white;
    box-shadow: 0 0 2rem 0.25rem var(--shadow-color-light);
    z-index: 5000;
    animation: back-button 0.6s ease;
}

@keyframes save-modal-button {
    0% {
        opacity: 0;
        transform: scale(0.5) translateX(100%) translateY(100%);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateX(0%) translateY(0%);
    }
}

.save-modal-button {
    position: absolute;
    right: 3rem;
    bottom: 3rem;
    height: 9rem;
    width: 9rem;
    border-radius: 6rem;
    color: var(--corporate-color-14-ligh);
    background-color: white;
    box-shadow: 0 0 2rem 0.25rem var(--corporate-color-14-ligh);
    z-index: 5000;
    animation: save-modal-button 0.6s ease;
}

.save-dashboard-button-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    position: absolute;
    bottom: 0rem;
    height: 17rem;
}

.save-dashboard-button {
    height: 9rem;
    width: 9rem;
    border-radius: 6rem;
    color: var(--corporate-color-14-ligh);
    background-color: white;
    box-shadow: 0 0 2rem 0.25rem var(--corporate-color-14-ligh);
    z-index: 5000;
    animation: fade-scale-item-buttons 0.6s ease;
}

@keyframes add-button {
    0% {
        opacity: 0;
        transform: scale(0.5) translateX(100%) translateY(100%);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateX(0%) translateY(0%);
    }
}

.add-button {
    position: absolute;
    right: 3rem;
    bottom: 3rem;
    height: 9rem;
    width: 9rem;
    border-radius: 6rem;
    color: var(--corporate-color-2);
    background-color: white;
    box-shadow: 0 0 2rem 0.25rem var(--shadow-color-light);
    z-index: 199;
    animation: add-button 0.6s ease;
}

.modal-add-button {
    position: absolute;
    right: 3rem;
    bottom: 3rem;
    height: 9rem;
    width: 9rem;
    border-radius: 6rem;
    color: var(--corporate-color-13);
    background-color: white;
    box-shadow: 0 0 2rem 0.25rem var(--corporate-color-13);
    z-index: 5000;
    animation: save-modal-button 0.6s ease;
}

.item-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 3rem;
    bottom: 3rem;
    height: 9rem;
    width: 9rem;
    border-radius: 6rem;
    color: white;
    background-color: white;
    z-index: 200;
    font-size: 5rem;
    box-shadow: 0 0 2rem 0.25rem var(--shadow-color-light);
}

@keyframes fade-scale-item-buttons {
    0% {
        transform: scale(0);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.item-button-3 {
    animation: fade-scale-item-buttons 0.9s ease;
}
.item-button-2 {
    animation: fade-scale-item-buttons 0.8s ease;
}
.item-button-1 {
    animation: fade-scale-item-buttons 0.7s ease;
}
.item-button-a {
    animation: fade-scale-item-buttons 0.6s ease;
}
.item-button-o {
    animation: fade-scale-item-buttons 0.5s ease;
}
.item-button-s {
    animation: fade-scale-item-buttons 0.4s ease;
}
.item-button-d {
    animation: fade-scale-item-buttons 0.3s ease;
}
.item-button-i {
    animation: fade-scale-item-buttons 0.2s ease;
}
.item-button-r {
    animation: fade-scale-item-buttons 0.1s ease;
}

.save-subscreen-button {
    position: absolute;
    right: 3rem;
    bottom: 3rem;
    height: 9rem;
    width: 9rem;
    border-radius: 6rem;
    color: var(--corporate-color-14-ligh);
    background-color: white;
    box-shadow: 0 0 2rem 0.25rem var(--corporate-color-14-ligh);
    z-index: 199;
    animation: fade-scale-mobile-buttons 0.3s ease;
}

.search-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: -6rem;
}

@keyframes search-button {
    0% {
        opacity: 0;
        transform: scale(0.5) translateY(100%);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateY(0%);
    }
}

.search-button {
    height: 12rem;
    width: 12rem;
    border-radius: 6rem;
    color: var(--corporate-color-2-light2);
    background-color: white;
    box-shadow: 0 0 2rem 0.25rem var(--shadow-color-light);
    z-index: 199;
    animation: search-button 0.6s ease;
}

.search-button-db {
    height: 12rem;
    width: 12rem;
    border-radius: 6rem;
    color: var(--corporate-color-2-light2);
    background-color: white;
    box-shadow: 0 0 2rem 0.25rem var(--shadow-color-light);
    z-index: 199;
    animation: search-button 0.6s ease;
}

.save-protocol-button-container {
    display: flex;
    justify-content: center;
    width: 100%;
    position: absolute;
    bottom: 3rem;
}

.save-protocol-button {
    height: 9rem;
    width: 9rem;
    border-radius: 6rem;
    color: var(--corporate-color-14-ligh);
    background-color: white;
    box-shadow: 0 0 2rem 0.25rem var(--corporate-color-14-ligh);
    z-index: 199;
    animation: search-button 0.6s ease;
}

.filter-button {
    height: 12rem;
    width: 12rem;
    border-radius: 6rem;
    color: var(--corporate-color-2-light2);
    background-color: white;
    box-shadow: 0 0 2rem 0.25rem var(--shadow-color-light);
    z-index: 199;
    animation: search-button 0.6s ease;
}

.search-button-icon-container {
    height: 100%;
    display: flex;
    align-items: flex-start;
}

.screen-selector-button {
    font-size: var(--font-size-mobile-large);
    height: 7.5rem;
    width: 7.5rem;
    padding: 0;
    border-radius: 9rem;
    color: var(--corporate-color-14);
}

.add-attendee-button {
    height: 7rem;
    width: 7rem;
    padding: 0;
    margin: 0 0 1rem 2rem;
    border-radius: 10rem;
    color: var(--corporate-color-14);
}

.items-meetings-toggle-button {
    font-size: var(--font-size-mobile-normal);
    padding: 0 2.5rem 0 2.5rem;
    height: 6rem;
    border-radius: 10rem;
    border: 1px solid var(--corporate-color-14-light);
    color: var(--corporate-color-14);
    box-shadow: 0 8px 12px -2px var(--corporate-color-14-light);
    box-sizing: border-box;
}

.login-button-mobile {
    font-size: var(--font-size-mobile-normal);
    padding: 0 2.5rem 0 2.5rem;
    height: 6rem;
    border-radius: 10rem;
    border: 1px solid var(--corporate-color-2-light3);
    color: var(--corporate-color-2-light2);
    box-shadow: 0 8px 12px -2px var(--shadow-color-ultralight);
    box-sizing: border-box;
}

.opps-sorting-button {
    font-size: var(--font-size-mobile-big);
    height: 7.5rem;
    width: 7.5rem;
    padding: 0;
    border-radius: 10rem;
    color: var(--corporate-color-13);
    margin-right: 2rem;
}

.opps-sorting-button:hover {
    background-color: white;
}

.list-buttons-mobile {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex: 1;
    animation: fader 0.5s ease;
}

.list-button {
    font-size: var(--font-size-mobile-normal);
    padding: 0 2.5rem 0 2.5rem;
    height: 6rem;
    border-radius: 10rem;
    border: 1px solid var(--corporate-color-2);
    color: var(--corporate-color-2);
    box-sizing: border-box;
    margin-top: 2rem;
}

.dashboard-top-buttons-container-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 4rem 0 1rem 0;
    padding: 0 2rem 0 2rem;
}

/* Flyout Menus */

.nav-menu-flyout-container {
    height: 98%;
    position: absolute;
    top: 1%;
    background-color: white;
    box-sizing: border-box;
    border-radius: 0 var(--font-size-mobile-big) var(--font-size-mobile-big) 0;
    border: 1px solid var(--color-2);
    box-shadow: 0 20px 15px -2px var(--shadow-color-light);
    z-index: 199;
}

.add-menu-flyout-container {
    height: 98%;
    position: absolute;
    top: 1%;
    background-color: white;
    box-sizing: border-box;
    border-radius: var(--font-size-mobile-big) 0 0 var(--font-size-mobile-big);
    border: 1px solid var(--color-3);
    box-shadow: 0 20px 15px -2px var(--shadow-color-normal);
    z-index: 199;
}

.filter-menu-flyout-container {
    position: absolute;
    height: 60vh;
    width: 96vw;
    left: 2vw;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: white;
    box-sizing: border-box;
    border-radius: var(--font-size-mobile-big) var(--font-size-mobile-big) 0 0;
    border: 1px solid var(--color-3);
    box-shadow: 0 0px 15px 0px var(--shadow-color-normal);
    overflow: hidden;
}

.selector-menu-flyout-container {
    position: absolute;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 2rem 0 2rem 0;
    background-color: white;
    box-sizing: border-box;
    border-radius: 0 0 var(--font-size-mobile-big) var(--font-size-mobile-big);
    border: 1px solid var(--color-3);
    box-shadow: 0 12px 24px -2px var(--shadow-color-light);
    z-index: 198;
    height: 48rem;
    overflow: hidden;
}

@keyframes opac-fader {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.flyout-backdrop {
    width: 100vw;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(10px);
    z-index: 100;
    animation: opac-fader 0.5s ease;
}

.project-selector-mobile {
    position: absolute;
    bottom: 0;
    width: calc(100% - 4rem);
    height: 8rem;
    padding: 0 2rem 0 2rem;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-mobile-normal);
    background-color: var(--corporate-color-1);
    color: white;
    z-index: 199;
    font-weight: normal;
}

.protocol-project-selector-mobile {
    position: absolute;
    top: 0;
    width: calc(100% - 4rem);
    background-color: white;
    height: 8rem;
    box-shadow: 0 12px 24px -2px var(--shadow-color-light);
    border-radius: 0 0 3.5rem 3.5rem;
    padding: 0 2rem 0 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: var(--font-size-mobile-normal);
    background-color: var(--corporate-color-1);
    color: white;
    z-index: 198;
    font-weight: normal;
}

/* Menu */

.nav-menu-content {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
}

.nav-menu-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 1rem 0 2rem;
    height: 8vh;
}

.add-menu-header {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-left: 1rem;
    height: 8vh;
}

.nav-menu-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 8vh;
    color: var(--corporate-color-2);
    padding: 0 2rem 0 2rem;
}

.menu-entry {
    font-size: 4rem;
}

/* Project Dashbard */

.mobile-dashboard-area {
    margin: 3rem 2rem 5rem 2rem;
}

.mobile-dashboard-area .header-container {
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
}

.headline-mobile {
    border: none;
    font-size: 5rem;
    color: var(--corporate-color-2);
}

.modal-headline-mobile {
    display: flex;
    align-items: center;
    border: none;
    font-size: 5rem;
    color: var(--corporate-color-2);
    margin: 2rem 0 3rem 2rem;
}

@keyframes filler {
    0% {
        opacity: 0;
        transform: scaleX(0);
    }
    100% {
        opacity: 1;
        transform: scaleX(1);
    }
}

@keyframes dropdown {
    0% {
        opacity: 0;
        transform: scaleY(0) scaleX(0.5);
    }
    100% {
        opacity: 1;
        transform: scaleY(1) scaleX(1);
    }
}

.modal-headline-filler {
    height: 2px;
    background-color: var(--corporate-color-7);
    transform-origin: left;
    flex: 1;
    margin: 0 2rem 0 2rem;
    animation: filler 1s ease;
}

.kanban-container-mobile {
    width: 100%;
    height: 80vh;
    overflow-x: scroll;
    overflow-y: hidden;
    white-space: nowrap;
    margin-top: 2rem;
    scroll-snap-type: x mandatory;
}

.board-element-mobile {
    display: inline-block;
    box-sizing: border-box;
    width: 97%;
    height: 97%;
    background-color: white;
    border: 2px solid var(--color-3);
    box-shadow: 0 0.5rem 1rem 2px var(--shadow-color-ultralight);
    border-radius: 3rem;
    text-align: center;
    margin: 1vh 3rem 0 1.5%;
    overflow-x: hidden;
    overflow-y: hidden;
    scroll-snap-align: center;
}

.board-element-mobile .header {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-between;
    margin: 2rem 2rem 2rem 2rem;
}

.board-element-mobile .content {
    height: inherit;
    overflow: scroll;
}

.board-element-mobile .header .count {
    height: 6.5rem;
    min-width: 4.5rem;
    padding: 0 1rem;
    border-radius: 3.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3.5rem;
    background-color: var(--corporate-color-7);
    color: var(--corporate-color-1);
    margin-right: 2rem;
}

.board-element-mobile .header .status {
    font-size: 3.5rem;
    color: var(--corporate-color-1);
    font-weight: 400;
}

/* Tops */

.dashboard-top-button-row-mobile {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-left: 2rem;
    padding-right: 2rem;
    border-bottom: 2px solid var(--color-2);
    height: 4rem;
    border-top-left-radius: var(--font-size-mobile-normal);
    border-top-right-radius: var(--font-size-mobile-normal);
}

.top-menu-container-mobile {
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
    background-color: white;
    width: fit-content;
    padding: 1rem;
    margin-top: 1px;
    border-radius: var(--font-size-mobile-medium);
    box-shadow: 0 5px 8px -2px var(--corporate-color-1-light3);
    z-index: 2;
    animation: menu-shuffle 0.4s ease;
}

.top-type-badge-mobile {
    display: flex;
    height: 5rem;
    width: 5rem;
    background-color: darkgray;
    color: white;
    border-radius: 3rem;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-mobile-normla);
    cursor: default;
}

.top-type-badge-protocol-mobile {
    display: flex;
    height: 5rem;
    width: 5rem;
    background-color: darkgray;
    color: white;
    border-radius: 3rem;
    align-items: center;
    justify-content: center;
    font-size: var(--font-size-mobile-normla);
}

.edited-indicator-mobile {
    text-align: center;
    font-size: 2rem;
    color: var(--corporate-color-1-light);
    margin: 0.5rem 10% 0.5rem 0;
}

.top-info-mobile {
    width: 100%;
    text-align: start;
    white-space: pre-line;
    font-size: 2.25rem;
}

.top-info-label-mobile {
    font-size: 1.5rem;
    color: var(--corporate-color-2);
    margin-top: 3px;
    white-space: nowrap;
}

.no-top-type-indicator {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
    width: 10rem;
    border-radius: 6rem;
    color: var(--corporate-color-2);
    z-index: 199;
    font-size: 5rem;
    box-shadow: 0 5px 8px 2px var(--shadow-color-ultralight);
}

.item-type {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 10rem;
    width: 10rem;
    border-radius: 6rem;
    color: white;
    z-index: 199;
    font-size: 5rem;
    box-shadow: 0 5px 8px 2px var(--shadow-color-ultralight);
}

.type-a {
    background-color: var(--corporate-color-14);
}

.type-o {
    background-color: var(--corporate-color-13);
}

.type-s {
    background-color: grey;
}

.type-d {
    background-color: var(--corporate-color-7);
}

.type-i {
    background-color: #c5c9d3;
}

.type-r {
    background-color: var(--corporate-color-12);
}

/* Gantt */

.gantt-chart-mobile {
    width: 100%;
    height: fit-content;
    overflow: scroll;
    white-space: nowrap;
    margin-top: 36px;
    border-radius: 3rem;
}

.gantt-content-mobile {
    display: flex;
    flex-direction: column;
    border-right: 7px solid darkgray;
}

.phases-rows-mobile {
    display: flex;
    flex-direction: column;
}

.gantt-chart-mobile .year-mobile {
    font-size: var(--font-size-mobile-large);
    margin-top: 24px;
    color: var(--color-2);
    position: relative;
    left: 6px;
    bottom: 5px;
    width: fit-content;
    z-index: -1;
}

.cws-row-mobile {
    display: flex;
    margin-bottom: 6px;
}

.cw-container-mobile {
    display: flex;
    align-items: center;
}

.cw-line-mobile {
    display: flex;
    align-items: center;
    width: 60;
    border-bottom: 1px solid var(--color-2);
    height: 0;
}

.cw-start-mobile {
    height: 0.75rem;
    width: 0.75rem;
    background-color: var(--color-2);
    border-radius: 0.5rem;
    position: relative;
    top: 0.5;
    right: 3.5;
    z-index: -1;
}

.cw-indicator-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 120;
    height: 3rem;
    border: var(--cw-border-width) solid var(--color-2);
    color: var(--corporate-color-1);
    border-radius: 1.5rem;
    box-shadow: 0 5px 8px -2px var(--shadow-color-ultralight);
    font-size: var(--font-size-mobile-smallium);
    background-color: white;
}

.top-count-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 4rem;
    width: 4rem;
    background-color: var(--corporate-color-7);
    font-size: var(--font-size-mobile-medium);
    color: var(--corporate-color-1);
    margin-right: 12px;
    border-radius: 2rem;
}

.gantt-phase-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15 0 5;
    height: 5rem;
    border-radius: 2.5rem;
    margin-top: 40px;
    background-color: white;
    border: 1px solid var(--color-3);
    box-sizing: border-box;
    box-shadow: 0 5px 8px -2px var(--shadow-color-light);
    transition: 0.3s ease;
    overflow: hidden;
}

.gantt-phase-mobile:hover {
    cursor: pointer;
    background-color: var(--corporate-color-14-light3);
    box-shadow: 0 5px 8px -2px var(--shadow-color-normal);
}

.phase-name-mobile {
    font-size: var(--font-size-mobile-medium);
    color: var(--corporate-color-1);
    cursor: pointer;
}

.milestone-indicator-mobile {
    height: 80;
    width: 80;
    margin-top: 40px;
    border-radius: 5px;
    background-color: var(--corporate-color-2);
    color: var(--corporate-color-7);
    transform: rotate(45deg);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 50px;
    transition: 0.3s ease;
}

.milestone-indicator-mobile:hover {
    cursor: pointer;
    color: var(--corporate-color-13);
    box-shadow: 0 0 12px -2px var(--corporate-color-13);
}

/* Opp Charts */

.layout-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.opp-charts-mobile {
    display: flex;
    align-items: flex-start;
}

.doughnut-container-mobile {
    margin: 0 auto;
    max-width: 100%;
    position: relative;
    width: 900px;
}

.inner-text-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 20%;
    right: 20%;
    bottom: 20%;
    left: 20%;
    padding: 1.25em 0;
    line-height: 120%;
    z-index: -1; /* Allow tooltips on top */
    overflow: hidden;
}

.title-mobile {
    font-size: var(--font-size-mobile-large);
    color: var(--corporate-color-2);
    margin-top: 1.5rem;
}

.info-mobile {
    font-size: var(--font-size-mobile-big);
    color: var(--corporate-color-1);
    margin-top: 4rem;
}

.title-critical-mobile {
    font-size: var(--font-size-mobile-large);
    color: var(--corporate-color-12);
    margin-top: 5rem;
}

.info-critical-mobile {
    font-size: var(--font-size-mobile-big);
    color: var(--corporate-color-12);
    margin-top: 4rem;
}

.chart-legend-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.legend-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.legend-item-mobile {
    display: flex;
    align-items: center;
    height: 6rem;
    margin-left: 5vw;
    margin-right: 5vw;
}

.legend-color-mobile {
    width: 3rem;
    height: 3rem;
    border-radius: 2.5rem;
}

.legend-label-mobile {
    color: var(--corporate-color-1);
    margin-left: 2rem;
    cursor: default;
    font-size: var(--font-size-mobile-normal);
}

/* Opp Table */

.table-container-mobile {
    width: fit-content;
    display: flex;
    flex-direction: column;
    padding: 1.5rem;
    border-radius: 3rem;
    margin-bottom: 10px;
    margin-top: 20px;
    cursor: default;
    background-color: white;
    border: 1px solid var(--color-3);
    box-shadow: 0 0.5rem 1rem 2px var(--shadow-color-ultralight);
    margin: 0 1rem 0 1rem;
}

.table-container-mobile .table-header-mobile {
    width: fit-content;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    border-bottom: 1px solid var(--corporate-color-2);
    margin-bottom: 18;
    padding-bottom: 3;
}

.table-container-mobile .header-entry-left-mobile {
    display: flex;
    justify-content: flex-start;
    font-size: var(--font-size-mobile-normal);
    width: 33vw;
    color: var(--corporate-color-2);
}

.table-container-mobile .header-entry-middle-mobile {
    display: flex;
    justify-content: center;
    font-size: var(--font-size-mobile-normal);
    width: 33vw;
}

.table-container-mobile .footer-entry-middle-mobile {
    display: flex;
    border-radius: 12px;
    justify-content: center;
    font-size: var(--font-size-mobile-normal);
    width: 33vw;
    transition: 0.1s ease;
    cursor: pointer;
}

.table-container-mobile .footer-entry-middle-mobile:hover {
    font-weight: 500;
    color: white;
    background-color: darkgray;
}

.table-container-mobile .header-entry-right-mobile {
    display: flex;
    justify-content: flex-end;
    font-size: var(--font-size-mobile-normal);
    width: 33vw;
    color: var(--corporate-color-2);
}

.table-container-mobile .table-row-mobile {
    width: fit-content;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding-bottom: 12px;
    margin-bottom: 12px;
}

.table-container-mobile .table-entry-left-mobile {
    display: flex;
    justify-content: flex-start;
    font-size: var(--font-size-mobile-normal);
    width: 33vw;
    overflow: hidden;
}

.table-container-mobile .table-entry-middle-mobile {
    display: flex;
    justify-content: center;
    font-size: var(--font-size-mobile-normal);
    border-radius: 12px;
    width: 33vw;
    transition: 0.1s ease;
    cursor: pointer;
}

.table-container-mobile .table-entry-middle-mobile:hover {
    font-weight: bold;
    color: white;
    background-color: darkgray;
}

.table-container-mobile .table-entry-right-mobile {
    display: flex;
    justify-content: flex-end;
    font-size: var(--font-size-mobile-normal);
    width: 33vw;
}

.table-container-mobile .footer-entry-right-mobile {
    width: 33vw;
    display: flex;
    justify-content: flex-end;
    color: var(--corporate-color-11);
    font-size: var(--font-size-mobile-normal);
}

.table-container-mobile .table-footer-mobile {
    width: fit-content;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    border-top-style: solid;
    border-top-width: 1px;
    border-top-color: var(--corporate-color-11);
    padding-top: 3px;
    margin-top: 6px;
}

.table-container-mobile .table-footer-critical-mobile {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Actions Chart */

.actions-chart-mobile {
    display: flex;
    justify-content: center;
    width: 96vw;
    margin-top: 36;
}

.actions-chart-mobile .inner-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 90vw;
}

.inner-mobile p {
    color: var(--corporate-color-1);
    font-size: var(--font-size-mobile-normal);
    margin-bottom: 20;
    margin-top: 4rem;
}

.project-selector-entry-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2.5rem;
    width: 33%;
    white-space: nowrap;
    overflow: hidden;
}

/* Meetings Timeline */

.no-content-message-mobile {
    display: flex;
    flex: 1;
    justify-content: center;
    margin-top: 24px;
    color: var(--corporate-color-1);
    font-size: var(--font-size-mobile-normal);
}

.timeline-container-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 3rem;
}

.timeline-inner-container-mobile {
    display: flex;
    justify-content: center;
    flex: 1;
}

.timeline-line-mobile {
    height: fit-content;
    width: 0;
    border: 1px solid var(--corporate-color-2);
    padding-bottom: 3rem;
}

.timeline-row-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90vw;
    position: relative;
    right: 45vw;
    margin-top: 3rem;
    margin-bottom: 3rem;
}

.timeline-row-line-mobile {
    height: 0;
    width: 25vw;
    border-bottom: 1px solid var(--corporate-color-2);
}

.detailed-meeting-container-mobile {
    width: 90vw;
    height: fit-content;
}

.detailed-meeting-buttons-mobile {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding-top: 1rem;
    height: 6rem;
    color: var(--corporate-color-2);
    border-top: 1px solid var(--corporate-color-2);
}

.dashboard-meeting-button-row-mobile {
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    color: white;
    background-color: var(--corporate-color-2);
    height: 4rem;
    overflow: hidden;
    padding: 0 1rem 0 2rem;
    border-top-left-radius: var(--font-size-mobile-normal);
    border-top-right-radius: var(--font-size-mobile-normal);
}

.month-badge-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-mobile-medium);
    color: var(--corporate-color-2);
    padding: 5px 8px 5px 8px;
    border-radius: 6rem;
    height: 5rem;
    border: 2px solid var(--corporate-color-2);
    min-width: 30vw;
    background-color: white;
    position: relative;
    right: 15.8vw;
}

.meeting-badge-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-mobile-medium);
    color: white;
    background-color: var(--corporate-color-2);
    padding: 3px 5px 3px 5px;
    border-radius: 6rem;
    height: 5rem;
    border: 1px solid var(--corporate-color-2);
    min-width: 30vw;
    transition: 0.2s ease;
    cursor: pointer;
}

.meeting-badge-mobile:hover {
    background-color: var(--corporate-color-4);
    border-color: var(--corporate-color-4);
}

.date-badge-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-mobile-medium);
    color: var(--corporate-color-2);
    padding: 5px 8px 5px 8px;
    border-radius: 6rem;
    height: 5rem;
    border: 2px solid var(--corporate-color-2);
    font-weight: bold;
    min-width: 30vw;
}

.date-badge-light-mobile {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: var(--font-size-mobile-medium);
    color: var(--corporate-color-2);
    background-color: white;
    padding: 3px 5px 3px 5px;
    border-radius: 6rem;
    height: 5rem;
    border: 1px solid var(--corporate-color-2);
    min-width: 30vw;
}

/* Global Dashboard */

@keyframes fade-mobile {
    0% {
        opacity: 0;
        transform: scale(0);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

@keyframes fade-scale-count-left {
    0% {
        opacity: 0;
        transform: scale(0) translateY(200%);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateY(0%);
    }
}

@keyframes fade-scale-count-right {
    0% {
        opacity: 0;
        transform: scale(0) translateY(-200%);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateY(0%);
    }
}

.fade-mobile {
    animation: fade-mobile 1.2s ease;
}

.fade-scale-count-left {
    animation: fade-scale-count-left 1.2s ease;
}

.fade-scale-count-right {
    animation: fade-scale-count-right 1.2s ease;
}

.global-count-middle-column {
    display: flex;
    flex-direction: column;
    width: 28rem;
    margin-top: 5.3rem;
}

.global-count-right-column {
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.global-count-opps-container {
    display: flex;
    justify-content: center;
    margin: 2rem 0 2rem 0;
}

.global-count-actions-container {
    align-items: flex-start;
    justify-content: flex-end;
    flex: 1;
    position: absolute;
    bottom: -5.3rem;
    margin-left: 2rem;
}

.global-count-container-mobile {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    width: fit-content;
}

.global-count-headline-mobile {
    color: var(--corporate-color-1);
    font-size: 4.3rem;
    cursor: pointer;
}
.global-count-number-mobile {
    color: var(--corporate-color-2);
    font-size: 6.3rem;
    cursor: pointer;
}

.project-overview-mobile {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.project-overview-mobile .project-mobile {
    display: flex;
    flex-direction: column;
    flex: 1;
    border-radius: 3rem;
    padding-bottom: 2rem;
    margin: 0.5rem 1rem 4.5rem 1rem;
    overflow: hidden;
    box-shadow: 0 5px 12px -2px var(--shadow-color-normal);
    background-color: white;
}

.project-mobile .header {
    height: 4rem;
    background-color: var(--corporate-color-5l);
    display: flex;
    align-items: center;
    padding-left: 24;
    padding-right: 18;
    color: var(--corporate-color-1);
    font-size: var(--font-size-mobile-medium);
}

.project-mobile .information {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 6rem;
    color: var(--corporate-color-1);
    font-size: var(--font-size-mobile-medium);
}

.project-mobile .column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;
    border-radius: 30px;
}

.project-mobile .title {
    font-size: var(--font-size-mobile-medium);
    color: var(--corporate-color-2);
    margin-top: 1rem;
}

.project-mobile .count {
    font-size: var(--font-size-mobile-large);
    color: var(--corporate-color-4);
    margin-bottom: 1rem;
}

.project-mobile .critical-title {
    font-size: var(--font-size-mobile-medium);
    color: var(--corporate-color-12);
}

.project-mobile .critical-count {
    font-size: var(--font-size-mobile-large);
    color: var(--corporate-color-12);
}

.project-mobile .project-container {
    display: flex;
    flex: 1;
    justify-content: center;
    border-bottom: 1px solid var(--corporate-color-5l);
    margin-bottom: 18px;
}

.project-mobile .project-information-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/* Fancy Arrows */

@keyframes left-arrow {
    0% {
        opacity: 0;
        transform: scale(0) translateX(100%) translateY(-100%);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateX(0%) translateY(0%);
    }
}

@keyframes right-arrow {
    0% {
        opacity: 0;
        transform: scale(0) translateX(-100%) translateY(100%);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateX(0%) translateY(0%);
    }
}

.fancy-outer-arrow-left {
    height: 14.5rem;
    width: 14.5rem;
    border-top: 4px solid var(--corporate-color-1);
    border-right: 4px solid var(--corporate-color-1);
    border-top-right-radius: 10rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: relative;
    animation: left-arrow 1.2s ease;
}

.fancy-inner-arrow-left {
    height: 13.5rem;
    width: 13.5rem;
    border-top: 4px solid var(--corporate-color-7);
    border-right: 4px solid var(--corporate-color-7);
    border-top-right-radius: 9.5rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    right: calc(1rem - 4px);
}

.outer-arrow-head-left {
    height: 2.5rem;
    width: 0;
    border: 2px solid var(--corporate-color-1);
    transform: rotate(35deg);
    position: absolute;
    right: -17px;
    bottom: -2px;
}

.inner-arrow-head-left {
    width: 2.5rem;
    height: 0;
    border: 2px solid var(--corporate-color-7);
    transform: rotate(35deg);
    position: absolute;
    left: -3px;
    top: 1.5rem;
}

.fancy-outer-arrow-right {
    height: 14.5rem;
    width: 14.5rem;
    border-bottom: 4px solid var(--corporate-color-7);
    border-left: 4px solid var(--corporate-color-7);
    border-bottom-left-radius: 10.5rem;
    position: relative;
    animation: right-arrow 1.2s ease;
}

.fancy-inner-arrow-right {
    height: 13.5rem;
    width: 13.5rem;
    border-bottom: 4px solid var(--corporate-color-1);
    border-left: 4px solid var(--corporate-color-1);
    border-bottom-left-radius: 9.5rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    position: absolute;
    left: calc(1rem - 4px);
}

.outer-arrow-head-right {
    width: 2.5rem;
    height: 0;
    border: 2px solid var(--corporate-color-1);
    transform: rotate(35deg);
    position: absolute;
    right: -3px;
    bottom: 1.5rem;
}

.inner-arrow-head-right {
    height: 2.5rem;
    width: 0;
    border: 2px solid var(--corporate-color-7);
    transform: rotate(35deg);
    position: absolute;
    left: -17px;
    top: -2px;
}

/* FIELDS */

.field.list-field .row-mobile {
    cursor: pointer;
    padding: var(--space-small);
    display: flex;
    align-items: center;
    flex-direction: row;
    flex: 1;
    margin: 0 2rem 2.5rem 2rem;
    padding-left: 2rem;
    box-shadow: 0 5px 8px -2px var(--corporate-color-2-light);
    border: 1px solid var(--corporate-color-2-light);
    border-radius: 5rem;
    min-height: 8.5rem;
    transition: 0.3s ease;
    background-color: white;
}

.row-mobile-content {
    display: flex;
    flex: 1;
    flex-direction: column;
    margin: 1rem;
}

.row-mobile .main-title {
    color: var(--corporate-color-1);
    font-size: 2.75rem;
}

.row-mobile .sub-title {
    color: var(--color-2);
    font-size: 2rem;
}

/* MESSAGES */

.dashboard-message-mobile {
    font-size: 5rem;
    color: var(--corporate-color-1-light);
    text-align: center;
    margin-top: 190px;
}

.select-top-type-message {
    font-size: 3.5rem;
    color: var(--corporate-color-2);
    margin-top: 1.5rem;
}

/* Dropdowns */

.mobile-app-container .css-hu0xre svg {
    height: 50;
    width: 50;
}

.mobile-app-container .css-11xx3ae-Input {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
}

.mobile-app-container .css-11xx3ae-Input div {
    font-size: 3.5rem;
}

.mobile-app-container .list-row {
    background-color: white;
    margin-left: var(--space-large-row);
    margin-right: var(--space-large-row);
    margin-top: var(--space-large-row);
    box-shadow: 0 5px 8px -2px var(--corporate-color-1-light4);
    border-radius: 3rem;
    overflow: hidden;
}

.mobile-app-container .css-tj5bde-svg {
    display: flex;
    width: 100%;
    justify-content: center;
    font-size: 5rem;
    height: 3rem;
    width: 5rem;
    color: var(--color-error);
}

/* Loader */

.mini-loader-container-mobile {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10rem;
    height: 6rem;
}

.mini-loader-mobile {
    border: 4px solid transparent;
    border-top: 4px solid var(--corporate-color-1);
    border-radius: 10rem;
    width: 5rem;
    height: 5rem;
    animation: spin 1.5s linear infinite;
}

.mini-loader2-mobile {
    position: relative;
    right: calc(5rem - 8px);
    border: 4px solid transparent;
    border-top: 4px solid var(--corporate-color-3);
    border-radius: 10rem;
    width: 3rem;
    height: 3rem;
    animation: spin 2s linear infinite;
}

.loader-container-mobile {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    padding-left: calc(6.25rem - 12px);
}

.loader-mobile {
    border: 6px solid transparent;
    border-top: 6px solid var(--corporate-color-1);
    border-radius: 10rem;
    width: 15rem;
    height: 15rem;
    animation: spin 1.5s linear infinite;
}

.loader2-mobile {
    position: relative;
    right: calc(12.5rem + 12px);
    border: 6px solid transparent;
    border-top: 6px solid var(--corporate-color-7);
    border-radius: 10rem;
    width: 10rem;
    height: 10rem;
    animation: spin 2s linear infinite;
}

.spinit {
    animation: spin 1.5s ease infinite;
}

/* Protocol Screen */

.meeting-attendees-area-mobile {
    margin: 0 2rem 0 2rem;
}

.meeting-attendees-mobile {
    height: 'fit-content';
}

.meeting-content-area-mobile {
    margin: 0 2rem 0 2rem;
}

/* Auth */

@keyframes login {
    0% {
        opacity: 0;
        transform: scale(0.5);
    }
    100% {
        opacity: 1;
        transform: scale(1);
    }
}

.login-container-mobile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: fit-content;
    width: 100%;
    margin: 2rem;
    border-radius: 5rem;
    padding: 2rem 0 3rem 0;
}

.zIndex10000 {
    z-index: 10000;
}

.fit-content {
    height: 9rem;
    width: 9rem;
    position: absolute;
    bottom: 15rem;
    left: 3rem;
}

.scale0-8 {
    transform: scale(0.8);
}

/* Datepicker */

.field.date-field.mobile {
    width: calc(100vw - 4rem);
    font-size: 3.5rem;
    color: var(--color-1);
    margin: 0 2rem 0 2rem;
    transition: 0.3s ease;
}
.field.date-field.mobile .datepicker {
    width: 100%;
    font-size: 3.5rem;
    font-weight: 400;
    border: 3px solid var(--color-3);
    color: var(--color-1);
    height: 8rem;
    margin: 0 2rem 0 0rem;
    transition: 0.3s ease;
}

.mobile-dates {
    width: 100%;
}

.mobile-dates .react-datepicker {
    height: fit-content;
    width: calc(96vw - 1.5rem);
    box-shadow: 0 0.5rem 1.5rem -2px var(--shadow-color-normal);
    border-radius: 3rem;
    border-color: var(--color-3);
}

.mobile-dates .react-datepicker__month-container {
    width: calc(96vw - 1.5rem);
    height: fit-content;
}

.mobile-dates .react-datepicker__header {
    position: relative;
    border-top-left-radius: 3rem;
    border-top-right-radius: 3rem;
    background-color: white;
    border-bottom: 2px solid var(--corporate-color-2);
}

.mobile-dates .react-datepicker__current-month {
    width: calc(96vw - 1.5rem);
    font-size: 3rem;
    color: var(--corporate-color-2);
}

.mobile-dates .react-datepicker__navigation--next {
    transform: scale(5);
    position: absolute;
    top: 2rem;
    right: 2rem;
}

.mobile-dates .react-datepicker__navigation--previous {
    transform: scale(5);
    position: absolute;
    top: 2rem;
    left: 2rem;
}

.mobile-dates .react-datepicker__month {
    font-size: 2rem;
}

.mobile-dates .react-datepicker__week {
    display: flex;
    justify-content: space-between;
    font-size: 2rem;
    width: calc(96vw - 4rem);
    margin: 2rem 0 2rem 1rem;
}

.mobile-dates .react-datepicker__day-names {
    display: flex;
    justify-content: space-between;
    font-size: 2rem;
    width: calc(96vw - 4rem);
    margin: 1.5rem 0 0 1rem;
}

.mobile-dates .react-datepicker__day-name {
    font-size: 2rem;
    width: 3rem;
    height: 3rem;
}

.mobile-dates .react-datepicker__day {
    font-size: 3rem;
    width: 3rem;
    height: 3rem;
}

.mobile-dates .react-datepicker__day--keyboard-selected {
    font-size: 3rem;
    width: 3rem;
    height: 3rem;
    color: var(--corporate-color-2);
    font-weight: bold;
    background-color: white;
    color: red;
}
